import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../utils/config';
import { AuthContext } from './AuthContext';

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [sidebarState, setSidebarState] = useState({
        profileImageUrl: null,
        notificationCount: 0,
    });

    const { userData } = useContext(AuthContext);

    const updateProfileImageUrl = useCallback((newUrl) => {
        setSidebarState((prevState) => ({
            ...prevState,
            profileImageUrl: newUrl,
        }));
    }, []);

    const updateNotificationCount = (count) => {
        setSidebarState((prevState) => ({
            ...prevState,
            notificationCount: count,
        }));
    };

    useEffect(() => {
        const fetchProfileImage = async () => {
            if (userData && userData.id_usuario) {
                try {
                    const response = await axios.get(`${API_BASE_URL}/cuenta/fotoPerfil/${userData.id_usuario}`);

                    if (response.data.success && response.data.fotoPerfilUrl) {
                        updateProfileImageUrl(response.data.fotoPerfilUrl);
                    } else {
                        updateProfileImageUrl(null);
                    }
                } catch (error) {
                    console.error('// LOG ERROR [SidebarContext] al obtener la foto de perfil:', error);
                    updateProfileImageUrl(null);
                }
            }
        };

        fetchProfileImage();
    }, [userData, updateProfileImageUrl]);

    return (
        <SidebarContext.Provider
            value={{
                sidebarState,
                updateProfileImageUrl,
                updateNotificationCount,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
