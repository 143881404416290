// src/components/Navbar/NavbarStyle.js
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: var(--negro);
    background-color: var(--blanco);
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }
`;

export const Logo = styled.div`
    img {
        height: 40px;
    }
`;

export const Navigation = styled.nav`
    display: flex;
    align-items: center;
    position: relative; /* Para posicionar el subrayado */
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: var(--blanco);
        border-top: 1px solid #ddd;
        max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
        opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
        overflow: hidden;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
`;

export const Menu = styled.ul`
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
    position: relative; /* Para el subrayado */

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
        gap: 0.5rem;
    }
`;

export const MenuItem = styled.li`
    position: relative;

    @media (max-width: 768px) {
        text-align: center;
        padding: 0.5rem 0;
    }

    &:hover {
        cursor: pointer;
    }

    &.no-hover:hover {
        background-color: transparent;
        cursor: default;
    }
`;

export const MenuLink = styled(NavLink)`
    color: var(--negro);
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: block;
    position: relative;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: var(--rojo);
    }

    &.active {
        color: var(--rojo);
    }

    @media (max-width: 768px) {
        padding: 0.75rem 1rem;
    }
`;

// Subrayado animado
export const Underline = styled.span`
    position: absolute;
    bottom: 0;
    left: ${({ left }) => left}px;
    width: ${({ width }) => width}px;
    height: 2px;
    background-color: var(--rojo);
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
    pointer-events: none;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AuthButtons = styled.div`
    display: flex;
    justify-content: center;

    .login-button,
    .pricing-button {
        margin-left: 1rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s, transform 0.3s;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            transform: translateY(-2px);
        }

        @media (max-width: 768px) {
            margin-left: 0;
            margin-top: 0.5rem;
            width: 100%;
        }
    }

    .login-button {
        background-color: var(--limon);
        color: var(--negro);

        &:hover {
            background-color: var(--limon);
        }
    }

    .pricing-button {
        background-color: var(--rojo);
        color: var(--blanco);

        &:hover {
            background-color: var(--rojo-oscuro);
        }
    }
`;

export const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 1100;

    span {
        height: 3px;
        width: 25px;
        background: var(--negro);
        margin-bottom: 4px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    &.open span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    &.open span:nth-child(2) {
        opacity: 0;
    }

    &.open span:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }

    @media (max-width: 768px) {
        display: flex;
    }
`;
