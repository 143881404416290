import React, { useState, useRef, useEffect, useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    Header,
    Logo,
    Navigation,
    Hamburger,
    Menu,
    MenuLink,
    MenuItem,
    Underline
} from './NavbarStyle';
import logo from '../../assets/homePage/logo.png';
import defaultProfilePicture from '../../assets/login/profile/default.png';

// Importamos AuthContext
import { AuthContext } from '../../context/AuthContext';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [underlineStyle, setUnderlineStyle] = useState({ left: 0, width: 0 });
    const navigationRef = useRef(null);
    const underlineRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    // Refs para los enlaces principales
    const inicioRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const linkRefs = {
        '/': inicioRef,
        '/about': aboutRef,
        '/contact': contactRef,
    };

    // Obtenemos isAuthenticated, userData desde el contexto
    const { isAuthenticated, userData } = useContext(AuthContext);

    // Función para actualizar la posición del subrayado
    const updateUnderline = () => {
        const currentPath = location.pathname;
        const currentRef = linkRefs[currentPath];
        if (currentRef && currentRef.current && underlineRef.current && navigationRef.current) {
            const navRect = navigationRef.current.getBoundingClientRect();
            const linkRect = currentRef.current.getBoundingClientRect();
            setUnderlineStyle({
                left: linkRect.left - navRect.left,
                width: linkRect.width,
            });
        } else {
            setUnderlineStyle({ left: 0, width: 0 });
        }
    };

    // Actualizar el subrayado al montar y al cambiar de ruta
    useEffect(() => {
        updateUnderline();
        window.addEventListener('resize', updateUnderline);
        return () => {
            window.removeEventListener('resize', updateUnderline);
        };
    }, [location.pathname]);

    // Cerrar el menú al hacer clic fuera
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                navigationRef.current &&
                !navigationRef.current.contains(event.target) &&
                !event.target.closest('.hamburger')
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    // Generar la foto real si userData.fotoPerfilUrl existe
    const photoUrl = userData?.fotoPerfilUrl || defaultProfilePicture;

    const handleUserClick = () => {
        // Al hacer click en el nombre/foto, lo llevamos a /dashboard
        navigate('/dashboard');
    };

    return (
        <Header>
            <Logo>
                <NavLink to="/">
                    <img src={logo} alt="Vitalink Logo" />
                </NavLink>
            </Logo>

            <Hamburger
                className={`hamburger ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span />
                <span />
                <span />
            </Hamburger>

            <Navigation isOpen={isOpen} ref={navigationRef}>
                <Menu>
                    {/* Enlaces principales */}
                    <MenuItem>
                        <MenuLink
                            exact="true"
                            to="/"
                            onClick={handleLinkClick}
                            ref={inicioRef}
                        >
                            Inicio
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            to="/about"
                            onClick={handleLinkClick}
                            ref={aboutRef}
                        >
                            Acerca de Nosotros
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            to="/contact"
                            onClick={handleLinkClick}
                            ref={contactRef}
                        >
                            Contacto
                        </MenuLink>
                    </MenuItem>

                    {/* Subrayado animado */}
                    <Underline ref={underlineRef} style={underlineStyle} />

                    {/* Botón de Precios SIEMPRE visible */}
                    <MenuItem
                        className="no-hover"
                        style={{
                            background: 'var(--rojo)',
                            borderRadius: '20px',
                            marginRight: '10px'
                        }}
                    >
                        <MenuLink
                            style={{ color: '#fff', fontWeight: 700 }}
                            to="/pricing"
                            onClick={handleLinkClick}
                        >
                            Precios
                        </MenuLink>
                    </MenuItem>

                    {/* Si está autenticado, mostrar foto+nombre clickable. Si no, botón Iniciar Sesión */}
                    {isAuthenticated && userData ? (
                        <MenuItem
                            className="no-hover"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                padding: '4px 10px',
                                background: 'var(--celeste)',
                                borderRadius: '20px',
                                cursor: 'pointer'
                            }}
                            onClick={handleUserClick}
                        >
                            <img
                                src={photoUrl}
                                alt="Avatar"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    objectFit: 'cover'
                                }}
                            />
                            <span style={{ color: '#fff', fontWeight: 'bold' }}>
                                {userData.name}
                            </span>
                        </MenuItem>
                    ) : (
                        <MenuItem
                            className="no-hover"
                            style={{
                                background: 'var(--celeste)',
                                fontWeight: 700,
                                borderRadius: '20px'
                            }}
                        >
                            <MenuLink
                                style={{ color: '#fff' }}
                                to="/login"
                                onClick={handleLinkClick}
                            >
                                Iniciar Sesión
                            </MenuLink>
                        </MenuItem>
                    )}
                </Menu>
            </Navigation>
        </Header>
    );
};

export default Navbar;
