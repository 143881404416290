import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
    Container,
    Content,
    Title,
    Input,
    ActionButtons,
    Button,
    FormColumn,
    FisioList,
    ListItem,
    FisioInfo,
    SelectButton,
    ButtonCancel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    TextArea,
    PatientItem,
    PatientDetails,
    MotiveInput,
    PatientsList,
    PatientNumber,
    Label
} from './AltaPacienteStyle';

import {API_BASE_URL} from "../../utils/config";
import ActivityFeed from "../../components/Feed/FeedActividad";
import {StyledModal} from "../../components/Modal";

import {useTransition, animated} from "react-spring";
import {AuthContext} from "../../context/AuthContext";
import Pagination from "../../components/Pagination/Pagination";
import {useSede} from "../../context/SedeContext";

const AltaPaciente = () => {
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [pacientes, setPacientes] = useState([]);
    const [filteredPacientes, setFilteredPacientes] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [motivo, setMotivo] = useState('');
    const {userData} = useContext(AuthContext);
    const { idSedeActual } = useSede();

    const [currentPage, setCurrentPage] = useState(1);
    const [patientsPerPage] = useState(10); // cuántos pacientes por página
    const [selectedPatients, setSelectedPatients] = useState([]);

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    // Calcular índices para paginación
    const indexOfLastPatient = currentPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    const currentPatients = filteredPacientes.slice(indexOfFirstPatient, indexOfLastPatient);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Manejo de selección de pacientes
    const togglePatientSelection = (patient) => {
        setSelectedPatients((prevSelected) => {
            const existingPatient = prevSelected.find(p => p.ID_USUARIO === patient.ID_USUARIO);
            if (existingPatient) {
                return prevSelected.filter(p => p.ID_USUARIO !== patient.ID_USUARIO);
            } else {
                return [...prevSelected, patient];
            }
        });
    };

    // Confirmar dar de alta a varios pacientes
    const handleConfirmDischarge = () => {
        const patientIds = selectedPatients.map(patient => patient.ID_USUARIO);
        const motivos = selectedPatients.map(patient => patient.motivo || '');

        axios.delete(`${API_BASE_URL}/paciente/eliminarPacientes`, {
            data: {
                patientIds,
                motivos,
                userId: userData.id_usuario
            }
        })
            .then(response => {
                toast.success('Pacientes eliminados exitosamente', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
                // Actualiza estado para removerlos de la lista
                setFilteredPacientes(prev =>
                    prev.filter(patient => !patientIds.includes(patient.ID_USUARIO))
                );
                setPacientes(prev =>
                    prev.filter(patient => !patientIds.includes(patient.ID_USUARIO))
                );
            })
            .catch(error => {
                toast.error('Error al eliminar pacientes', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
            });

        setIsConfirmModalOpen(false);
        setSelectedPatients([]);
    };

    // Captura el motivo de cada paciente
    const handleMotivoChange = (id, newMotivo) => {
        setSelectedPatients(selectedPatients.map(p =>
            p.ID_USUARIO === id ? { ...p, motivo: newMotivo } : p
        ));
    };

    // Cargar los pacientes al montar el componente o cambiar la sede
    useEffect(() => {
        axios.get(`${API_BASE_URL}/paciente/todosLosPacientes/${idSedeActual}`)
            .then(response => {
                if (response.data && Array.isArray(response.data.pacientes)) {
                    setPacientes(response.data.pacientes);
                    setFilteredPacientes(response.data.pacientes);
                } else {
                    setPacientes([]);
                    setFilteredPacientes([]);
                    toast.error('No se encontraron pacientes.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                }
            })
            .catch(error => {
                console.error('Error obteniendo pacientes:', error);
                setPacientes([]);
                setFilteredPacientes([]);
            });
    }, [idSedeActual]);

    // Filtrado de pacientes por nombre, apellido y correo
    useEffect(() => {
        const filtered = pacientes.filter(fisio => {
            if (!fisio) return false;

            // Filtrar por nombre
            if (nombre !== '') {
                const nombrePaciente = fisio.NOMBRE ? fisio.NOMBRE.toLowerCase() : '';
                if (!nombrePaciente.includes(nombre.toLowerCase())) {
                    return false;
                }
            }

            // Filtrar por apellido
            if (apellido !== '') {
                const apellidoPaciente = fisio.APELLIDO ? fisio.APELLIDO.toLowerCase() : '';
                if (!apellidoPaciente.includes(apellido.toLowerCase())) {
                    return false;
                }
            }

            // Filtrar por email
            if (email !== '') {
                const emailPaciente = fisio.EMAIL ? fisio.EMAIL.toLowerCase() : '';
                if (!emailPaciente.includes(email.toLowerCase())) {
                    return false;
                }
            }

            return true;
        });

        setFilteredPacientes(filtered);
    }, [nombre, apellido, email, pacientes]);

    // Manejo de cerrar/abrir modal de confirmación
    const handleConfirmOpen = () => {
        if (selectedPatients.length === 0) {
            toast.warn('No has seleccionado ningún paciente', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
            });
            return;
        }
        setIsConfirmModalOpen(true);
    };

    const handleConfirmClose = () => {
        setIsConfirmModalOpen(false);
    };

    // Manejo de modal para dar de alta a un solo paciente (no se usa mucho en tu código actual)
    const handleModalOpen = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
        setMotivo('');
    };

    // Manejo de eliminación de un solo paciente (no se usa mucho en tu código actual)
    const handleDelete = () => {
        if (!selectedId) {
            toast.warn('Por favor, selecciona un paciente para eliminar', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
            });
            return;
        }

        axios.delete(`${API_BASE_URL}/paciente/eliminarPaciente/${selectedId}`, {
            data: {
                userId: userData.id_usuario
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success('Paciente eliminado exitosamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                    setFilteredPacientes(prev =>
                        prev.filter(admin => admin.ID_USUARIO !== selectedId)
                    );
                    setPacientes(prev =>
                        prev.filter(admin => admin.ID_USUARIO !== selectedId)
                    );
                    setIsModalOpen(false);
                    setSelectedId(null);
                    setMotivo('');
                } else {
                    toast.error('Error al eliminar paciente', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                    setIsModalOpen(false);
                    setSelectedId(null);
                    setMotivo('');
                }
            })
            .catch((error) => {
                console.error('Error eliminando paciente:', error);
            });
    };

    // Animaciones con react-spring (si las usas)
    const transitions = useTransition(filteredPacientes, {
        from: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        enter: item => async (next) => {
            await next({ opacity: 1, transform: 'translate3d(0,0px,0)' });
        },
        leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        keys: item => item.ID_USUARIO
    });

    return (
        <Container>
            <Content>
                <ToastContainer />
                <FormColumn className={"pacienteEliminar"}>
                    <Title>Dar de alta paciente</Title>

                    <Label>Buscar por nombre: </Label>
                    <Input
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                    />

                    <Label>Buscar por apellido: </Label>
                    <Input
                        value={apellido}
                        onChange={e => setApellido(e.target.value)}
                    />

                    <Label>Buscar por correo electrónico: </Label>
                    <Input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />

                    {currentPatients.length > 0 ? (
                        <FisioList>
                            {currentPatients.map(item => (
                                <ListItem key={item.ID_USUARIO}>
                                    <FisioInfo>
                                        {item.NOMBRE} {item.APELLIDO} ({item.EMAIL})
                                    </FisioInfo>
                                    <SelectButton onClick={() => togglePatientSelection(item)}>
                                        {selectedPatients.some(p => p.ID_USUARIO === item.ID_USUARIO)
                                            ? 'Deseleccionar'
                                            : 'Seleccionar'
                                        }
                                    </SelectButton>
                                </ListItem>
                            ))}
                        </FisioList>
                    ) : (
                        <div>No se encontraron pacientes.</div>
                    )}

                    {/* Se añade currentPage como prop para que Pagination muestre la página activa */}
                    <Pagination
                        patientsPerPage={patientsPerPage}
                        totalPatients={filteredPacientes.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />

                    <ActionButtons>
                        <Button onClick={handleConfirmOpen}>Dar de alta</Button>
                    </ActionButtons>
                </FormColumn>

                <ActivityFeed
                    idRol={'4, 3, 2'}
                    idAccion={3}
                    idInstitucion={userData.id_institucion}
                    idEntidadAfectada={1}
                    className={"FeedActividades"}
                />
            </Content>

            {/* Modal de confirmación de alta */}
            <StyledModal isOpen={isConfirmModalOpen} onRequestClose={handleConfirmClose}>
                <ModalContent>
                    <ModalHeader>
                        <h2>Confirmar Alta de Pacientes</h2>
                    </ModalHeader>
                    <ModalBody>
                        <PatientsList>
                            {selectedPatients.map((patient, index) => (
                                <PatientItem key={patient.ID_USUARIO}>
                                    <PatientNumber>{index + 1}.</PatientNumber>
                                    <PatientDetails>
                                        {patient.NOMBRE} {patient.APELLIDO}
                                    </PatientDetails>
                                    <MotiveInput
                                        type="text"
                                        value={patient.motivo || ''}
                                        onChange={(e) => handleMotivoChange(patient.ID_USUARIO, e.target.value)}
                                        placeholder="Indica el motivo del alta"
                                    />
                                </PatientItem>
                            ))}
                        </PatientsList>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleConfirmDischarge}>Confirmar Alta</Button>
                        <ButtonCancel onClick={handleConfirmClose} cancelBtn>
                            Cancelar
                        </ButtonCancel>
                    </ModalFooter>
                </ModalContent>
            </StyledModal>
        </Container>
    );
};

export default AltaPaciente;
