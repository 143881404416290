import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTimes, FaSyncAlt, FaCalendarAlt } from 'react-icons/fa';
import { MdOutlinePercent } from 'react-icons/md';
import {
    Overlay,
    NotificationsSliderContainer,
    CloseButtonSlider,
    SlideWrapper,
    Slide,
    SlideHeader,
    SlideIcon,
    SlideTitle,
    SlideMessage,
    SlideButton
} from './NotificationsSliderStyle';

// Definir las notificaciones fuera del componente para evitar recrearlas en cada render
const hardcodedNotifications = [
    {
        id: "001",
        title: "Nueva Actualización",
        message: "Se ha lanzado una nueva versión con funcionalidades mejoradas. ¡Actualiza ahora y descubre lo nuevo!",
    },
    {
        id: "002",
        title: "Recordatorio de Evento",
        message: "Mañana a las 10:00 AM tienes una reunión importante. Revisa la agenda para estar al día.",
    },
    {
        id: "003",
        title: "Cambio de Políticas",
        message: "Hemos actualizado nuestras políticas de privacidad. Revisa las novedades para continuar utilizando nuestros servicios.",
    },
];

const NotificationsSlider = () => {
    const [displayNotifications, setDisplayNotifications] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (hardcodedNotifications.length > 0) {
            const recentNotifications = hardcodedNotifications.slice(-10).reverse();
            setDisplayNotifications(recentNotifications);
            setIsVisible(false);
        }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        pauseOnHover: true,
        adaptiveHeight: false, // Mantener altura fija
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    background: "#ffffff", // Color de los puntos
                    margin: "0 5px"
                }}
            ></div>
        )
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    const getIcon = (title) => {
        switch (title) {
            case "Nueva Actualización":
                return <FaSyncAlt />;
            case "Recordatorio de Evento":
                return <FaCalendarAlt />;
            case "Cambio de Políticas":
            case "Promoción Especial":
                return <MdOutlinePercent />;
            default:
                return <FaSyncAlt />;
        }
    };

    // Fondo con degradado usando la paleta: De celeste a limón
    const bgGradient = "linear-gradient(135deg, var(--celeste), var(--limon))";

    return (
        <Overlay>
            <NotificationsSliderContainer role="dialog" aria-modal="true" aria-label="Notificaciones">
                <CloseButtonSlider onClick={handleClose} aria-label="Cerrar Notificaciones">
                    <FaTimes />
                </CloseButtonSlider>
                <Slider {...settings}>
                    {displayNotifications.map((notification) => (
                        <SlideWrapper key={notification.id}>
                            <Slide bgGradient={bgGradient}>
                                <SlideHeader>
                                    <SlideIcon>{getIcon(notification.title)}</SlideIcon>
                                    <SlideTitle>{notification.title}</SlideTitle>
                                </SlideHeader>
                                <SlideMessage>{notification.message}</SlideMessage>
                                <SlideButton onClick={() => alert(`Más información sobre: ${notification.title}`)}>
                                    Más información
                                </SlideButton>
                            </Slide>
                        </SlideWrapper>
                    ))}
                </Slider>
            </NotificationsSliderContainer>
        </Overlay>
    );
};

export default NotificationsSlider;
