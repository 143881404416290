// src/components/Modal/RenewalModal.jsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, Input } from './RenewalModalStyle';
import axios from 'axios';
import { API_BASE_URL_BILLING_SERVICE } from '../../utils/config';
import { toast } from 'react-toastify';

Modal.setAppElement('#root');

const RenewalModal = ({ isOpen, onRequestClose, onRenewSuccess }) => {
    const [sedes, setSedes] = useState([]);
    const [selectedSede, setSelectedSede] = useState(null);
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [loading, setLoading] = useState(false);

    const userData = JSON.parse(localStorage.getItem('userData') || '{}');

    useEffect(() => {
        if (isOpen && userData.id_usuario) {
            axios
                .get(`${API_BASE_URL_BILLING_SERVICE}/payment/get-user-sedes`, {
                    params: { userId: userData.id_usuario }
                })
                .then((res) => {
                    if (res.data.success) {
                        setSedes(res.data.sedes || []);
                        if ((res.data.sedes || []).length === 1) {
                            setSelectedSede(res.data.sedes[0].ID_SEDE);
                        }
                    } else {
                        toast.error('No fue posible cargar las sedes del usuario.');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    toast.error('Error cargando sedes para la renovación.');
                });
        } else {
            setSedes([]);
            setSelectedSede(null);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    const handleRenew = async () => {
        if (!selectedSede) {
            toast.warn('Por favor seleccione la sede a renovar.');
            return;
        }
        if (!cardNumber || !expiryDate || !cvv) {
            toast.warn('Por favor complete todos los campos de la tarjeta.');
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL_BILLING_SERVICE}/payment/renew`, {
                email: userData.email, // Para buscar el customer en Stripe
                userId: userData.id_usuario,
                sedeId: selectedSede,
                cardNumber,
                expiryDate,
                cvv
            });

            if (response.data.success) {
                toast.success('Suscripción renovada exitosamente.');
                onRequestClose(); // Cerrar modal
                onRenewSuccess(); // Hacer login directo al dashboard
            } else {
                toast.error(response.data.error || 'No fue posible renovar.');
            }
        } catch (error) {
            console.error('Error al renovar suscripción:', error);
            toast.error('Error al renovar suscripción. Verifica tus datos.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    width: '400px',
                    margin: 'auto',
                    height: 'auto',
                    padding: '20px'
                }
            }}
        >
            <h2>Renovar Suscripción</h2>
            <p>Selecciona la sede a renovar y proporciona los datos de tu tarjeta.</p>

            <div style={{ marginBottom: '10px' }}>
                <label style={{ fontWeight: 'bold' }}>Sede:</label>
                <select
                    value={selectedSede || ''}
                    onChange={(e) => setSelectedSede(e.target.value)}
                    style={{ width: '100%', marginTop: '5px' }}
                >
                    <option value="">-- Selecciona una sede --</option>
                    {sedes.map((s) => (
                        <option key={s.ID_SEDE} value={s.ID_SEDE}>
                            {s.NOMBRE_SEDE ? s.NOMBRE_SEDE : `Sede #${s.ID_SEDE}`}
                        </option>
                    ))}
                </select>
            </div>

            <Input
                type="text"
                placeholder="Número de tarjeta"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
            />
            <Input
                type="text"
                placeholder="Fecha de expiración (MM/YY)"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
            />
            <Input
                type="text"
                placeholder="CVV"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
            />

            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <Button onClick={onRequestClose} style={{ background: 'var(--rojo)' }}>
                    Cancelar
                </Button>
                <Button onClick={handleRenew} disabled={loading}>
                    {loading ? 'Procesando...' : 'Renovar'}
                </Button>
            </div>
        </Modal>
    );
};

export default RenewalModal;
