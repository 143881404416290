// src/context/AuthContext.js

import React, { createContext, useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import useIdleTimer from '../Hook/useIdleTimer';
import { useWebSocket } from './WebSocketContext';
import axios from 'axios';
import { API_BASE_URL } from '../utils/config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const wsContext = useWebSocket();

    const didMountRef = useRef(false);

    const setSessionToken = useCallback((token) => {
        if (token) {
            localStorage.setItem('sessionToken', token);
        } else {
            localStorage.removeItem('sessionToken');
        }
    }, []);

    const logout = useCallback(() => {

        const sessionToken = localStorage.getItem('sessionToken');

        if (wsContext && wsContext.ws && wsContext.ws.readyState === WebSocket.OPEN) {
            try {
                wsContext.ws.send(JSON.stringify({ type: 'LOGOUT', payload: { sessionToken } }));
            } catch (error) {
                console.error('// LOG ERROR en logout -> error al enviar mensaje de logout:', error);
            }
        }

        setIsAuthenticated(false);

        localStorage.removeItem('sessionToken');
        localStorage.removeItem('userData');

        if (userData?.id_usuario) {
            const sedeKey = `sede_seleccionada_${userData.id_usuario}`;
            localStorage.removeItem(sedeKey);
        }

        setUserData(null);

        if (wsContext && wsContext.closeWebSocket) {
            wsContext.closeWebSocket();
        }

        navigate('/login', { replace: true });
    }, [navigate, wsContext, userData]);

    const isDashboardRoute = location.pathname.startsWith('/dashboard');

    const showIdleTimeoutToast = useCallback(() => {
        toast.error('Tu sesión se ha cerrado por inactividad.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
        });
        logout();
    }, [logout]);

    useIdleTimer(
        1200000,
        isDashboardRoute ? showIdleTimeoutToast : null,
        isDashboardRoute
    );

    useEffect(() => {

        if (didMountRef.current) {
            return;
        }
        didMountRef.current = true;

        const existingToken = localStorage.getItem('sessionToken');
        const storedUserData = localStorage.getItem('userData');

        if (existingToken && storedUserData) {
            axios
                .get(`${API_BASE_URL}/auth/verifyToken`, {
                    headers: { Authorization: `Bearer ${existingToken}` },
                })
                .then((response) => {
                    if (response.data.success) {
                        setIsAuthenticated(true);
                        setUserData(JSON.parse(storedUserData));
                    } else {
                        logout();
                    }
                })
                .catch((error) => {
                    logout();
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [logout]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                loading,
                setLoading,
                userData,
                setUserData,
                logout,
                setSessionToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
