// frontend/components/IngresarPacienteStyle.js

import styled from 'styled-components';
import Modal from 'react-modal';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    background: #f9f9f9;

    .react-datepicker-popper {
        z-index: 999999 !important;
    }

    .react-datepicker {
        z-index: 999999 !important;
    }
    .react-datepicker__tab-loop {
        z-index: 999999 !important;
    }
`;

export const Title = styled.h1`
    color: #2c3e50;
    margin-bottom: 30px;
    font-size: 2em;
    text-align: center;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ddd;
    padding: 8px 12px;
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px auto;
`;

export const SearchInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    font-size: 1em;
    color: #333;
    background: transparent;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    gap: 20px;
    margin-top: 20px;
`;

export const Card = styled.div`
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    }
`;

export const CardImageWrapper = styled.div`
    width: 100%;
    height: 180px;
    background: #f0f0f0;
    display:flex;
    align-items:center;
    justify-content:center;
`;

export const CardTitle = styled.h3`
    font-size: 1.1em;
    color: #2c3e50;
    padding: 15px;
    margin:0;
`;

export const PreviewContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius:8px;
    padding:20px;
    box-sizing:border-box;
`;

export const PreviewHeader = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:20px;
`;

export const BackButton = styled.button`
    display:flex;
    align-items:center;
    background: #3498db;
    color:#fff;
    border:none;
    border-radius:4px;
    padding:8px 12px;
    font-size:1em;
    cursor:pointer;
    transition: background 0.2s ease;
    &:hover {
        background: #2980b9;
    }
`;

export const IngresarButton = styled.button`
    background: #27ae60;
    color:#fff;
    border:none;
    border-radius:4px;
    padding:12px 20px;
    font-size:1em;
    cursor:pointer;
    transition: background 0.2s ease;
    &:hover {
        background: #229954;
    }
`;

export const NoResults = styled.div`
    text-align:center;
    color:#888;
    margin-top:20px;
    font-size:1.1em;
`;

export const LoadingMessage = styled.div`
    text-align:center;
    color:#666;
    margin-top:20px;
    font-size:1.1em;
`;

export const StyledModal = styled(Modal).attrs({
    ariaHideApp: false
})`
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 62, 80, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        background-color: #ecf0f1;
        padding: 30px;
        width: 90%;
        max-height: 80%;
        overflow-y: auto;
        border-radius: 4px;
        position: relative;
        @media (min-width: 768px) {
            width: 400px;
        }
        h2 {
            color: #2c3e50;
        }
    }
`;

export const ButtonAceptar = styled.button`
    background-color: #27ae60;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background-color: #229954;
    }
`;

export const ButtonCancelar = styled.button`
    background-color: #c0392b;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background-color: #a93226;
    }
`;

export const ListItem = styled.li`
    padding: 12px;
    background-color: #ecf0f1;
    border: 1px solid #95a5a6;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    color: #2c3e50;
    &:hover {
        background-color: #d0d3d4;
    }
    &.selected {
        background-color: #3498db;
        color: #fff;
    }
`;

export const IndicadorGuardado = styled.div`
    display: ${({ mostrar }) => (mostrar ? 'flex' : 'none')};
    align-items: center;
    background: #2ecc71;
    color: #fff;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 4px;
    font-weight: bold;
    svg {
        margin-right: 5px;
    }
`;

export const FichaHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom:20px;
    img {
        max-height:120px;
    }
`;

export const FichaFooter = styled.div`
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    background-color: #27ae60;
    padding:10px 0;
    margin-top: 50px;
    word-break:break-word;
    white-space:normal;
`;

export const FichaContent = styled.div`
    background-color: #ecf0f1;
    padding:20px;
    border-radius:8px;
    max-width:100%;
    word-break:break-word;
    position:relative;
    height: auto;
    overflow: auto;
`;
