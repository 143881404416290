import styled, { keyframes } from 'styled-components';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';

export const Sidebar = styled.div`
    height: 100vh;
    background: linear-gradient(160deg, var(--celeste-rgba) 0%, #1c1c1c 55%, #000000 100%);
    color: var(--blanco);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    /* Permitir que los popouts salgan del contenedor */
    overflow-y: auto!important;
    overflow-x: visible!important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    @media (max-width: 768px) {
        width: 100%!important;
        max-height: 150vh!important;
        border-radius: 0;
        overflow-x: hidden!important; /* En móvil, no solemos necesitar popout */
    }
`;

export const ProfileImage = styled.div`
    width: 200px;
    height: 200px;
    background-color: var(--gris-oscuro);
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
        position: relative;
        margin-top: 20px;
        width: 80px;
        height: 80px;
        align-self: center;
    }
`;

export const UserInfo = styled.div`
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        position: relative;
        align-self: center;
        margin-top: 0;
    }
`;

export const Menu = styled.div`
    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;

/**
 * Ajustamos el tamaño de íconos y texto; reducimos font-weight.
 */
export const MenuItem = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--blanco);
    cursor: pointer;
    padding: 10px 15px;
    font-weight: ${props => (props.bold ? '500' : '400')};
    text-transform: ${props => (props.bold ? 'uppercase' : 'none')};
    background-color: ${props => props.active ? 'rgba(3, 114, 255, 0.3)' : 'transparent'};
    margin: 6px 10px;
    border-radius: 8px;
    transition: all 0.4s ease;
    font-size: ${props => props.sidebarOpen ? '0.85rem' : '0.9rem'};

    &:hover, &:focus {
        background-color: ${props => props.active
                ? 'rgba(3, 114, 255, 0.3)'
                : 'rgba(3, 114, 255, 0.2)'
        };
        transform: translateY(-1px);
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    svg {
        margin-right: 14px;
        /* Cambiamos el tamaño del ícono según si está abierto o cerrado */
        font-size: ${props => props.sidebarOpen ? '18px' : '26px'};
    }

    span {
        white-space: nowrap;
        overflow: hidden;
    }

    @media (max-width: 768px) {
        width: 90%;
        margin: 6px auto;
        border-radius: 6px;
        &:hover, &:focus {
            transform: none;
            box-shadow: none;
        }
        svg {
            font-size: 20px !important;
        }
    }
`;

export const SubMenu = styled.div`
    overflow: hidden;
    max-height: ${props => (props.active ? '500px' : '0')};
    transition: max-height 0.5s ease;

    @media (max-width: 768px) {
        transform-origin: top;
        transform: scaleY(${props => (props.active ? '1' : '0')});
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        display: block;
        height: auto;
        overflow: hidden;
        visibility: ${props => (props.active ? 'visible' : 'hidden')};
    }
`;

/**
 * SubMenuItem hereda de MenuItem, pero ajusta algunos paddings y bordes.
 */
export const SubMenuItem = styled(MenuItem)`
    padding: 10px 40px;
    font-weight: 500;
    text-transform: uppercase;
    border-top: none;
    background-color: transparent;
    margin: 4px 30px;
    border-radius: 6px;
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.1);

    &:hover, &:focus {
        background-color: rgba(3, 114, 255, 0.1);
        transform: none;
        box-shadow: none;
    }

    svg {
        margin-right: 0;
    }

    @media (max-width: 768px) {
        padding: 10px 40px;
        margin: 4px 20px;
        border-radius: 4px;
        border-top: none;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
`;

export const ChevronIcon = styled.i`
    transform: ${props => (props.rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease;
    margin-left: auto;
`;

/**
 * Botón de abrir/cerrar sidebar, con más margen arriba cuando está cerrado.
 */
export const SidebarButton = styled.button`
    background: var(--rojo-oscuro);
    border: none;
    color: var(--blanco);
    padding: 10px;
    position: fixed;
    top: ${props => props.open ? '20px' : '20px'};
    left: ${props => props.open ? '250px' : '0'};
    transition: left 0.3s ease, top 0.3s ease;
    z-index: 2000;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.25);

    &:focus {
        outline: none;
    }

    &:hover {
        background: #a82525;
    }

    @media (max-width: 768px) {
        position: fixed;
        top: 20px !important; /* en móvil mantenemos top fijo */
        left: 10px;
        z-index: 2050;
        padding: 12px;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.25);

        &:hover {
            background: var(--rojo-oscuro);
        }
    }
`;

export const Sede = styled.div`
    font-weight: 700;
    margin-top: 5px;
`;

export const SidebarLogoContainer = styled.div`
    width: 100%;
    padding: 20px 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 12px;
`;

export const SidebarLogo = styled.img`
    border-radius: ${props => props.collapsed ? '50%' : '0'};
    max-width: ${props => props.collapsed ? '40px' : '80%'};
    max-height: ${props => props.collapsed ? '40px' : '80px'};
    object-fit: contain;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
`;

/**
 * Tooltip con position: fixed (se maneja la posición externamente).
 * Se le quitó la posición "absolute" con left:70px para evitar cortes.
 */
export const Tooltip = styled.div`
    background: #2f2f2f;
    background: linear-gradient(120deg, #2f2f2f, #4f4f4f);
    color: #fff;
    padding: 8px 12px;
    font-size: 0.875rem;
    border-radius: 4px;
    white-space: nowrap;
    pointer-events: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
        width: 0;
        height: 0;
        border-right: 6px solid #2f2f2f;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
`;

export const PopoutContainer = styled.div`
    background: #1c1c1c;
    border-radius: 8px;
    padding: 10px 0;
    z-index: 3000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-grow: 1;
    padding: 20px;
    width: calc(100% - 250px);

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 10px;
        display: block;
        margin-top: 0;
        width: 100%;
    }
`;

export const Box = styled.div`
    background-color: var(--blanco);
    box-shadow: 0px 4px 6px var(--negro-rgba-01);
    margin: 10px;
    padding: 20px 20px 60px 20px;
    position: relative;
    min-height: 180px;

    @media (max-width: 768px) {
        width: 95%;
        margin: 10px 0;
        padding: 10px;
        min-height: 180px;
    }
`;

export const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    left: 5%;
    position: relative;
    overflow: hidden;

    canvas {
        max-width: 100%;
        max-height: 100%;
    }

    @media (max-width: 768px) {
        width: 90vw;
        left: 0%;
        height: auto;

        canvas {
            width: 100%;
            height: auto;
        }
    }
`;

export const ChartContainerTime = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 300px;
    position: relative;
`;

export const BoxTitle = styled.h3`
    text-align: center;
    margin: 10px 0;
`;

export const TableCell = styled.td`
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 768px) {
        padding: 8px;
        font-size: 0.8em;
    }
`;

export const BoxButton = styled.button`
    background-color: var(--celeste);
    color: var(--blanco);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
`;

export const TableContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    max-height: 300px;
    overflow: auto;
    height: 300px;
    position: relative;
    @media (max-width: 768px) {
        overflow-x: auto;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--gris);
    border-radius: 4px;
    margin-left: -10px;
`;

export const BoxTitleMobile = styled(BoxTitle)`
    @media (max-width: 768px) {
        display: block;
        width: 100%;
        text-align: center;
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        padding: 10px;
        border-bottom: 1px solid var(--gris);
        text-align: center;
    }
`;

export const Thead = styled.thead`
    background: #0F0F0F !important;
    color: #fff;
    th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: var(--negro);
    }
`;

export const HeaderTitle = styled.h1`
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
`;

export const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    z-index: 10000;

    &:focus {
        border-color: #00bfa5;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 191, 165, 0.5);
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 6px;
    }
`;

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    @media (max-width: 768px) {
        margin: 5px 0;
    }

    label {
        margin-bottom: 5px;
        font-weight: bold;
    }
`;

export const DatePickerLabel = styled.label`
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
    display: block;
`;

export const DatePickerWrapper = styled.div`
    flex: 1;
`;

export const FilterIcon = styled.div`
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 1000;
    color: #333;
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const ModalContainer = styled.div`
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
`;

export const ModalContent = styled.div`
    width: 80%;
    max-width: 500px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const CloseButton = styled.button`
    align-self: flex-end;
    background: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
`;

export const PageButton = styled.button`
    background-color: ${props => props.isActive ? 'var(--celeste)' : 'var(--gris-claro)'};
    color: ${props => props.isActive ? 'var(--blanco)' : 'var(--negro)'};
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 5px;

    &:hover {
        background-color: var(--celeste);
        color: var(--blanco);
    }

    &:disabled {
        cursor: not-allowed;
        background-color: var(--gris);
    }
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        margin-top: 0px;
        margin-bottom: 40px;
    }
`;

export const Ellipsis = styled.span`
    padding: 10px 15px;
    margin: 0 5px;
    color: var(--negro);
    font-weight: bold;
`;

export const ButtonAceptar = styled.button`
    padding: 10px 20px;
    border: none;
    background-color: var(--azul);
    color: var(--blanco);
    border-radius: 5px;
    box-shadow: 0 2px 5px var(--negro-rgba-03);
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: var(--azul);
        cursor: pointer;
        transform: translateY(-2px);
    }
`;

const slideIn = keyframes`
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const SliderContainer = styled.div`
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 600px;
    background: var(--celeste);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: 3000;
    animation: ${slideIn} 0.5s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;

    @media (max-width: 768px) {
        top: 10px;
        padding: 8px 15px;
    }
`;

export const Slide = styled.div`
    text-align: center;
    padding: 20px 10px;
    color: var(--blanco);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SlideTitle = styled.h3`
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--blanco);

    @media (max-width: 768px) {
        font-size: 1em;
    }
`;

export const SlideMessage = styled.p`
    margin: 10px 0 0 0;
    font-size: 1em;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`;

export const CloseButtonSlider = styled(FaTimes)`
    position: absolute;
    top: 10px;
    right: 15px;
    color: var(--blanco);
    cursor: pointer;
    font-size: 1.5em;
    transition: color 0.3s;

    &:hover {
        color: #ccc;
    }

    @media (max-width: 768px) {
        top: 8px;
        right: 10px;
        font-size: 1.2em;
    }
`;

export const NotificationContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NotificationBellContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

