import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';

export const SedeContext = createContext();

export const useSede = () => useContext(SedeContext);

export const SedeProvider = ({ children }) => {
    const { userData } = useContext(AuthContext);

    const [idSedeActual, setIdSedeActual] = useState(null);
    const [nombreSedeActual, setNombreSedeActual] = useState('');
    const [isSedeInfoLoaded, setIsSedeInfoLoaded] = useState(false);
    const [idTipoSede, setIdTipoSede] = useState(null);

    const prevUserIdRef = useRef(null);

    useEffect(() => {
        if (
            !userData ||
            !userData.id_usuario ||
            !Array.isArray(userData.sedes) ||
            userData.sedes.length === 0
        ) {
            setIsSedeInfoLoaded(false);
            setIdSedeActual(null);
            setNombreSedeActual('');
            setIdTipoSede(null);
            return;
        }

        if (prevUserIdRef.current && prevUserIdRef.current !== userData.id_usuario) {
            localStorage.removeItem(`sede_seleccionada_${prevUserIdRef.current}`);
        }
        prevUserIdRef.current = userData.id_usuario;

        const sedeGuardada = localStorage.getItem(
            `sede_seleccionada_${userData.id_usuario}`
        );

        if (sedeGuardada) {
            const sedeParseada = JSON.parse(sedeGuardada);

            const sedeEnUsuario = userData.sedes.find(
                (sede) => sede && sede.ID_SEDE === sedeParseada.idSedeActual
            );

            if (sedeEnUsuario) {
                setIdSedeActual(sedeEnUsuario.ID_SEDE);
                setNombreSedeActual(sedeEnUsuario.NOMBRE);
                setIdTipoSede(sedeEnUsuario.ID_TIPO_SEDE);
                setIsSedeInfoLoaded(true);
                return;
            }
        }

        const primeraSede = userData.sedes.find(
            (sede) => sede && sede.ID_SEDE !== undefined
        );
        if (primeraSede) {
            setIdSedeActual(primeraSede.ID_SEDE);
            setNombreSedeActual(primeraSede.NOMBRE);
            setIdTipoSede(primeraSede.ID_TIPO_SEDE);
            setIsSedeInfoLoaded(true);
        } else {
            setIsSedeInfoLoaded(false);
            setIdSedeActual(null);
            setNombreSedeActual('');
            setIdTipoSede(null);
        }
    }, [userData]);

    useEffect(() => {
        if (userData?.id_usuario && idSedeActual) {
            const sedeData = {
                idSedeActual,
                nombreSedeActual,
                idTipoSede,
            };
            localStorage.setItem(
                `sede_seleccionada_${userData.id_usuario}`,
                JSON.stringify(sedeData)
            );
        }
    }, [idSedeActual, nombreSedeActual, idTipoSede, userData]);

    const changeSede = (newSedeId) => {
        if (!isSedeInfoLoaded) {
            console.warn('// LOG: [SedeContext] isSedeInfoLoaded = false => ignoring');
            return;
        }

        if (userData && Array.isArray(userData.sedes)) {
            const sedeSeleccionada = userData.sedes.find(
                (sede) => sede && sede.ID_SEDE && sede.ID_SEDE.toString() === newSedeId
            );

            if (sedeSeleccionada) {
                setIdSedeActual(sedeSeleccionada.ID_SEDE);
                setNombreSedeActual(sedeSeleccionada.NOMBRE);
                setIdTipoSede(sedeSeleccionada.ID_TIPO_SEDE);
            } else {
                setIdSedeActual(null);
                setNombreSedeActual('');
                setIdTipoSede(null);
            }
        }
    };

    return (
        <SedeContext.Provider
            value={{
                idSedeActual,
                nombreSedeActual,
                changeSede,
                isSedeInfoLoaded,
                idTipoSede,
            }}
        >
            {children}
        </SedeContext.Provider>
    );
};
