import styled, { keyframes } from 'styled-components';

/* Animación de entrada desde el centro con escala */
const scaleIn = keyframes`
    0% {
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

/* Efecto ligero al hacer hover en el slide */
const hoverPop = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.02);
    }
`;

/* Overlay que cubre toda la pantalla */
export const Overlay = styled.div`
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.5); /* Mayor transparencia */
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
`;

/* Contenedor principal del slider */
export const NotificationsSliderContainer = styled.div`
    position: relative;
    z-index: 9999;
    font-family: 'Poppins', sans-serif;
    animation: ${scaleIn} 0.5s ease forwards;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8); /* Mayor transparencia */
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0,0,0,0.2);
    padding: 30px;
    box-sizing: border-box;

    /* Ancho y alto base del contenedor donde se mostrará el slider */
    max-width: 800px;
    width: 90vw;
    max-height: 80vh;
    overflow: hidden;

    .slick-dots {
        bottom: -30px; /* Ajustar posición de los dots */
    }

    .slick-dots li button:before {
        font-size: 12px;
        color: #ffffff; /* Color de los puntos */
        opacity: 0.75;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #ffffff; /* Color del punto activo */
    }

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 90vw;
    }
`;

/* Botón para cerrar el slider */
export const CloseButtonSlider = styled.button`
    position: absolute;
    top: 15px;
    right: 20px;
    background: var(--rojo);
    border: none;
    cursor: pointer;
    color: var(--blanco);
    font-size: 1.2em;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    transition: background 0.3s ease;

    &:hover {
        background: var(--rojo-oscuro);
    }

    @media (max-width: 768px) {
        top: 10px;
        right: 15px;
        font-size: 1em;
        width: 30px;
        height: 30px;
    }
`;

/* Wrapper para cada slide */
export const SlideWrapper = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
`;

/* Cada slide */
export const Slide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Asegura que el botón esté al final */
    padding: 30px;
    background: ${(props) => props.bgGradient || 'var(--blanco)'};
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--blanco);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    width: 700px;
    height: 450px; /* Altura fija para todos los slides */

    @media (max-width: 768px) {
        width: 90vw;
        height: 60vh;
        padding: 20px;
    }

    &:hover {
        animation: ${hoverPop} 0.3s forwards;
        box-shadow: 0 10px 30px rgba(0,0,0,0.25);
    }
`;

/* Header del slide */
export const SlideHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

/* Icono del slide */
export const SlideIcon = styled.div`
    margin-right: 15px;
    font-size: 2.5em;
    color: var(--blanco);

    @media (max-width: 768px) {
        font-size: 2em;
    }
`;

/* Título del slide */
export const SlideTitle = styled.h3`
    margin: 0;
    font-size: 2em;
    font-weight: 700;
    color: var(--blanco);

    @media (max-width: 768px) {
        font-size: 1.6em;
    }
`;

/* Mensaje del slide */
export const SlideMessage = styled.p`
    flex-grow: 1;
    margin: 0;
    font-size: 1.2em;
    line-height: 1.6;
    color: var(--blanco);

    @media (max-width: 768px) {
        font-size: 1em;
    }
`;

/* Botón "Más información" */
export const SlideButton = styled.button`
    align-self: flex-start;
    background: var(--blanco);
    color: var(--celeste);
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    margin-top: 20px;

    &:hover {
        background: rgba(255,255,255,0.9);
        transform: translateY(-2px);
    }

    @media (max-width: 768px) {
        font-size: 0.95em;
        padding: 10px 20px;
    }
`;
