// src/pages/Pricing/PricingStyle.js

import styled, { keyframes } from 'styled-components';

/* Animación de fade-in */
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

/* Contenedor principal de toda la sección de precios */
export const PricingContainer = styled.section`
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 4rem 0rem;
    background-color: var(--blanco);
    animation: ${fadeIn} 1s ease-in-out;
    overflow: hidden;
    text-align: center;

    @media (max-width: 768px) {
        padding: 2rem 1rem;
    }
`;

/* Título principal */
export const PricingTitle = styled.h1`
    font-size: 3rem;
    color: var(--negro);
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

/* Subtítulo */
export const SubTitle = styled.p`
    font-size: 1.3rem;
    color: var(--negro);
    margin-bottom: 3rem;

    @media (max-width: 768px) {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }
`;

/* Contenedor que envuelve las tarjetas de planes */
export const PlansWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

/* Tarjeta individual de cada plan */
export const PlanCard = styled.div`
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.08);
    padding: 2rem 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    }
`;

/* Encabezado del plan (nombre) */
export const PlanHeader = styled.h2`
    font-size: 1.8rem;
    color: var(--celeste);
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

/* Precio del plan */
export const PlanPrice = styled.p`
    font-size: 2rem;
    font-weight: 600;
    color: var(--negro);
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

/* Precio original tachado */
export const OriginalPrice = styled.span`
    text-decoration: line-through;
    color: #888;
    font-size: 1.2rem;
`;

/* Badge de descuento */
export const DiscountBadge = styled.span`
    background-color: #ff4d4f;
    color: #fff;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-top: 0.5rem;
`;

/* Cuerpo de la tarjeta */
export const PlanBody = styled.div`
    text-align: left;
    margin-bottom: 2rem;
`;

/* Lista de características/funcionalidades */
export const PlanFeatures = styled.ul`
    list-style: none;
    padding: 0;
`;

/* Cada item de la lista de características */
export const PlanFeatureItem = styled.li`
    font-size: 1rem;
    color: #333;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
        border-bottom: none;
    }
`;

/* Sección de Extras */
export const ExtrasSection = styled.div`
    margin-top: 1rem;
`;

/* Título de Extras */
export const ExtrasTitle = styled.h3`
    font-size: 1.2rem;
    color: var(--negro);
    margin-bottom: 0.5rem;
`;

/* Item de Extra */
export const ExtraItem = styled.p`
    font-size: 1rem;
    color: #555;
    margin: 0.3rem 0;
    display: flex;
    align-items: center;

    span {
        margin-left: 0.5rem;
        color: #888;
        font-size: 0.9rem;
    }
`;

/* Separador */
export const Separator = styled.hr`
    border: none;
    border-top: 1px solid #ddd;
    margin: 1rem 0;
`;

/* Pie de la tarjeta (botón de compra) */
export const PlanFooter = styled.div`
    text-align: center;
`;

/* Botón de compra */
export const PurchaseButton = styled.button`
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    color: #fff;
    background-color: var(--celeste);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: uppercase;

    &:hover {
        background-color: #0284fe; /* o un tono más oscuro de celeste */
    }
`;

/* Fondo con SVGs decorativos */
export const BackgroundSVGContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
        position: absolute;
    }
`;

/* Popup (modal) para errores */
export const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

/* Contenido del popup */
export const PopupContent = styled.div`
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
`;

/* Botón para cerrar el popup */
export const CloseButton = styled.button`
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--celeste);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0284fe;
    }
`;
