import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa';
import Navbar from '../../components/Navbar/Navbar';
import {
    BillingContainer,
    BillingTitle,
    BillingForm,
    BillingInput,
    BillingButton,
    BillingLabel,
    BillingError,
    BillingSection,
    CreditCardContainer,
    CreditCard3D,
    CardDetails,
    CardNumber,
    CardHolder,
    CardExpiry,
    CardCvc,
    TooltipContainer,
    TooltipContent,
    RequiredAsterisk
} from './BillingStyle';
import { API_BASE_URL_BILLING_SERVICE, API_BASE_URL } from '../../utils/config';
import { AuthContext } from '../../context/AuthContext';

// Inicializar Stripe con la clave pública
const stripePromise = loadStripe('pk_test_51Pp2jVP8m7371eZyB3dTDowrN8UCjKdrpla1DaqbOtubHf7Dogb6ukjTKBfUDAaBy81ViLhErdI3KZH7C1MROmgV00epg4sLn6');

const BillingFormComponent = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();

    const { userData } = useContext(AuthContext);

    // Plan seleccionado (viene por state en React Router)
    const selectedPlan = location.state?.plan || {};

    // Estados de datos del formulario
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [phone, setPhone] = useState('+502');
    const [institutionName, setInstitutionName] = useState('');
    const [institutionAddress, setInstitutionAddress] = useState('');
    const [institutionPhone, setInstitutionPhone] = useState('');
    const [sedeName, setSedeName] = useState('');
    const [sedeAddress, setSedeAddress] = useState('');
    const [zip, setZip] = useState('');

    // Para mostrar errores
    const [error, setError] = useState(null);

    // Rotación de la tarjeta 3D
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    // Listado de instituciones y sedes para autocompletar
    const [institutions, setInstitutions] = useState([]);
    const [sedes, setSedes] = useState([]);

    // Estado para mostrar spinner de carga
    const [loading, setLoading] = useState(false);

    // Mensajes de validación (tooltip)
    const [validationMessages, setValidationMessages] = useState([]);

    // Cargar las instituciones al montar el componente
    useEffect(() => {
        const fetchInstitutions = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/general/instituciones`);
                setInstitutions(response.data);
            } catch (error) {
                console.error('Error fetching institutions:', error);
                toast.error('Error al obtener las instituciones.');
            }
        };
        fetchInstitutions();
    }, []);

    // Cuando cambia institución, buscar la dirección/teléfono y sedes
    const handleInstitutionChange = async (e) => {
        const value = e.target.value.trim();
        setInstitutionName(value);

        const selectedInstitution = institutions.find(
            (inst) => inst.NOMBRE.toLowerCase() === value.toLowerCase()
        );

        if (selectedInstitution) {
            setInstitutionAddress(selectedInstitution.DIRECCION || '');
            setInstitutionPhone(selectedInstitution.TELEFONO || '');

            try {
                const response = await axios.get(
                    `${API_BASE_URL}/general/instituciones/${selectedInstitution.ID_INSTITUCION}/sedes`
                );
                setSedes(response.data);
            } catch (error) {
                console.error('Error fetching sedes:', error);
                toast.error('Error al obtener las sedes de la institución.');
                setSedes([]);
            }
        } else {
            setInstitutionAddress('');
            setInstitutionPhone('');
            setSedes([]);
        }
    };

    // Cuando cambia la sede, buscar la dirección de la sede
    const handleSedeChange = (e) => {
        const value = e.target.value.trim();
        setSedeName(value);

        const selectedSede = sedes.find(
            (sede) => sede.NOMBRE.toLowerCase() === value.toLowerCase()
        );
        if (selectedSede) {
            setSedeAddress(selectedSede.DIRECCION || '');
        } else {
            setSedeAddress('');
        }
    };

    // Manejar eventos de Stripe Elements (card number, cvc, etc.)
    const handleInputChange = (event) => {
        setError(event.error ? event.error.message : null);
    };

    // Efecto "3D" de la tarjeta según posición del mouse
    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        const x = (offsetY / e.target.offsetHeight) * 30 - 15;
        const y = (offsetX / e.target.offsetWidth) * 30 - 15;
        setRotation({ x, y });
    };

    // Volver la tarjeta al estado normal al salir el mouse
    const handleMouseLeave = () => {
        setRotation({ x: 0, y: 0 });
    };

    // Validación del formulario
    const validateForm = () => {
        const messages = [];

        // Validar campos obligatorios
        if (!email.trim()) messages.push('Correo Electrónico');
        if (!name.trim()) messages.push('Nombre');
        if (!lastName.trim()) messages.push('Apellido');
        if (!birthdate.trim()) messages.push('Fecha de Nacimiento');
        if (!phone || phone.length < 5) messages.push('Teléfono');
        if (!institutionName.trim()) messages.push('Institución');
        if (!institutionAddress.trim()) messages.push('Dirección de la Institución');
        if (!institutionPhone.trim()) messages.push('Teléfono de la Institución');
        if (!zip.trim()) messages.push('Código Postal');

        // Validar formato del email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailRegex.test(email)) {
            messages.push('Correo Electrónico inválido');
        }

        // Validar código postal (ejemplo: 5 dígitos)
        const zipRegex = /^\d{5}$/;
        if (zip && !zipRegex.test(zip)) {
            messages.push('Código Postal inválido');
        }

        // Si la institución ya existe, pedimos sede
        const selectedInstitution = institutions.find(
            (inst) => inst.NOMBRE.toLowerCase() === institutionName.toLowerCase()
        );
        if (selectedInstitution) {
            if (!sedeName.trim()) {
                messages.push('Sede');
            } else {
                // Si la sede existe en la BD, avisamos
                const selectedSede = sedes.find(
                    (sede) => sede.NOMBRE.toLowerCase() === sedeName.toLowerCase()
                );
                if (selectedSede) {
                    // Esto es opcional; depende de tu lógica
                    messages.push('Sede ya existente');
                }
            }
        }

        setValidationMessages(messages);
        return messages.length === 0;
    };

    // Re-validar cada vez que cambie algún campo
    useEffect(() => {
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        email,
        name,
        lastName,
        birthdate,
        phone,
        institutionName,
        institutionAddress,
        institutionPhone,
        zip,
        sedeName,
        sedes,
        institutions
    ]);

    // Envío del formulario
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            setError('Stripe.js no ha cargado todavía. Por favor, inténtalo de nuevo más tarde.');
            toast.error('Stripe.js no ha cargado todavía. Inténtalo más tarde.');
            return;
        }

        if (!validateForm()) {
            toast.error('Por favor, completa todos los campos requeridos correctamente.');
            return;
        }

        setLoading(true);

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            setError('Los detalles de la tarjeta no se han cargado correctamente.');
            toast.error('Los detalles de la tarjeta no se han cargado correctamente.');
            setLoading(false);
            return;
        }

        try {
            // Crear PaymentMethod en Stripe
            const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    email: email,
                    name: `${name} ${lastName}`,
                    address: {
                        line1: institutionAddress,
                        postal_code: zip
                    },
                    phone: phone
                }
            });

            if (stripeError) {
                setError(stripeError.message);
                toast.error(stripeError.message);
                setLoading(false);
                return;
            }

            // Datos para tu backend
            const paymentData = {
                amount: selectedPlan.precio * 100, // centavos
                currency: 'usd',
                description: selectedPlan.titulo,
                name,
                lastName,
                birthdate,
                email,
                phone,
                planId: selectedPlan.id,
                institutionName,
                institutionAddress,
                institutionPhone,
                sedeName,
                sedeAddress,
                paymentMethodId: paymentMethod.id,
                editedBy: null
            };

            // Llamada al backend (BillingService)
            const paymentResponse = await axios.post(
                `${API_BASE_URL_BILLING_SERVICE}/payment`,
                paymentData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Verificar la respuesta
            if (paymentResponse.status !== 200 && paymentResponse.status !== 201) {
                const errorMsg = paymentResponse.data.error || 'El pago falló';
                setError(errorMsg);
                toast.error(errorMsg);
                setLoading(false);
            } else {
                toast.success('Pago realizado con éxito. Se ha enviado un correo.');

                // Esperar 1 seg para icono de éxito, luego 3 seg para redirigir
                setTimeout(() => {
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                }, 1000);
            }
        } catch (error) {
            console.error('Error procesando el pago:', error);
            setError('Ocurrió un error inesperado al procesar tu pago.');
            toast.error('Ocurrió un error inesperado al procesar tu pago.');
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <BillingContainer>
                <BillingTitle>Procesar Pago</BillingTitle>

                <CreditCardContainer onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                    <CreditCard3D
                        style={{
                            transform: `rotateY(${rotation.y}deg) rotateX(${rotation.x}deg)`
                        }}
                    >
                        <CardDetails>
                            <CardNumber>1234 5678 9012 3456</CardNumber>
                            <CardHolder>JOHN DOE</CardHolder>
                            <CardExpiry>12/25</CardExpiry>
                            <CardCvc>123</CardCvc>
                        </CardDetails>
                    </CreditCard3D>
                </CreditCardContainer>

                <BillingForm onSubmit={handleSubmit}>
                    {/* Sección 1 */}
                    <BillingSection>
                        <BillingLabel>
                            Correo Electrónico<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Nombre<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Apellido<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Fecha de Nacimiento<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="date"
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Teléfono<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <PhoneInput
                            international
                            defaultCountry="GT"
                            value={phone}
                            onChange={setPhone}
                            required
                            aria-required="true"
                        />
                    </BillingSection>

                    {/* Sección 2 */}
                    <BillingSection>
                        <BillingLabel>
                            Institución<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={institutionName}
                            onChange={handleInstitutionChange}
                            placeholder="Escribe el nombre de la institución o selecciónala"
                            required
                            list="institution-list"
                            aria-required="true"
                        />
                        <datalist id="institution-list">
                            {institutions.map((institution) => (
                                <option
                                    key={institution.ID_INSTITUCION}
                                    value={institution.NOMBRE}
                                />
                            ))}
                        </datalist>

                        <BillingLabel>
                            Sede{' '}
                            {institutions.find(
                                (inst) =>
                                    inst.NOMBRE.toLowerCase() ===
                                    institutionName.toLowerCase()
                            ) && '(Requerido)'}
                            {institutions.find(
                                (inst) =>
                                    inst.NOMBRE.toLowerCase() ===
                                    institutionName.toLowerCase()
                            ) && <RequiredAsterisk>*</RequiredAsterisk>}
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={sedeName}
                            onChange={handleSedeChange}
                            placeholder="Escribe el nombre de la sede o selecciónala"
                            list="sede-list"
                            required={
                                institutions.find(
                                    (inst) =>
                                        inst.NOMBRE.toLowerCase() ===
                                        institutionName.toLowerCase()
                                )
                                    ? true
                                    : false
                            }
                            aria-required={
                                institutions.find(
                                    (inst) =>
                                        inst.NOMBRE.toLowerCase() ===
                                        institutionName.toLowerCase()
                                )
                                    ? 'true'
                                    : 'false'
                            }
                        />
                        <datalist id="sede-list">
                            {sedes.map((sede) => (
                                <option key={sede.ID_SEDE} value={sede.NOMBRE} />
                            ))}
                        </datalist>

                        <BillingLabel>
                            Dirección de la Institución<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={institutionAddress}
                            onChange={(e) => setInstitutionAddress(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Teléfono de la Institución<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={institutionPhone}
                            onChange={(e) => setInstitutionPhone(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>Dirección de la Sede (Opcional)</BillingLabel>
                        <BillingInput
                            type="text"
                            value={sedeAddress}
                            onChange={(e) => setSedeAddress(e.target.value)}
                        />

                        <BillingLabel>
                            Código Postal<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </BillingSection>

                    {/* Datos de la tarjeta */}
                    <BillingSection>
                        <BillingLabel>
                            Número de Tarjeta<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4'
                                        }
                                    },
                                    invalid: {
                                        color: '#9e2146'
                                    }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    <BillingSection>
                        <BillingLabel>
                            Fecha de Expiración<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4'
                                        }
                                    },
                                    invalid: {
                                        color: '#9e2146'
                                    }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    <BillingSection>
                        <BillingLabel>
                            CVC<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardCvcElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4'
                                        }
                                    },
                                    invalid: {
                                        color: '#9e2146'
                                    }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    {error && <BillingError>{error}</BillingError>}

                    <TooltipContainer>
                        <BillingButton
                            type="submit"
                            disabled={!stripe || loading || validationMessages.length > 0}
                        >
                            {loading ? (
                                <FaSpinner className="spinner" />
                            ) : (
                                `Pagar $${selectedPlan.precio}`
                            )}
                        </BillingButton>

                        {/* Tooltip con la lista de validaciones pendientes */}
                        {validationMessages.length > 0 && (
                            <TooltipContent>
                                <strong>Revisa lo siguiente:</strong>
                                <ul>
                                    {validationMessages.map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            </TooltipContent>
                        )}
                    </TooltipContainer>
                </BillingForm>
            </BillingContainer>

            {/* Contenedor de Toasts */}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
        </>
    );
};

const Billing = () => {
    return (
        <Elements stripe={stripePromise}>
            <BillingFormComponent />
        </Elements>
    );
};

export default Billing;
