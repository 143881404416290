import styled from 'styled-components';
import {
    AiFillStar,
    AiOutlineClose
} from 'react-icons/ai';

export const BuilderContainer = styled.div`
    display: flex;
    min-height: 100vh;
    background-color: #f4f6f8;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export const Toolbox = styled.div`
    background-color: #202424;
    padding: 20px;
    border-right: none;
    width: 260px;
    overflow-y: auto;
    border-radius: 10px;

    @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        h3 {
            display: none;
        }
    }
    h3 {
        margin: 0;
        color: #ecf0f1;
    }
`;

export const ToolboxItem = styled.div`
    background-color: transparent;
    padding: 12px;
    margin-bottom: 10px;
    border: 1px dashed #95a5a6;
    border-radius: 4px;
    cursor: grab;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    color: #ecf0f1;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: rgba(59,89,152,0.2);
    }
    svg {
        margin-right: 8px;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

export const Canvas = styled.div`
    flex-grow: 1;
    padding: 20px;
    background-color: transparent;
    position: relative;
    overflow-y: auto;
`;

export const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const TitleEditor = styled.input`
    flex: 1;
    font-size: 28px;
    font-weight: bold;
    border: none;
    border-bottom: 2px solid #2980b9;
    padding: 8px;
    margin-bottom: 10px;
    outline: none;
    background-color: transparent;
    color: #000;
    word-break: break-word;
    white-space: normal;
    max-width:100%;
    @media (min-width: 768px) {
        margin-bottom: 0;
    }
`;

export const PropertyEditorWrapper = styled.div`
    background-color: #ecf0f1;
    padding: 20px;
    border-left: 1px solid #e0e0e0;
    width: 300px;
    @media (max-width: 767px) {
        width: 100%;
        border-left: none;
        border-top: 1px solid #e0e0e0;
    }
    h3 {
        margin-bottom: 20px;
        color: #2c3e50;
    }
    p {
        color: #2c3e50;
    }
`;

export const PropertyEditorField = styled.div`
    margin-bottom: 15px;
    label {
        display: block;
        margin-bottom: 6px;
        color: #2c3e50;
    }
    input,
    select {
        width: 100%;
        padding: 8px;
        border: none;
        border-bottom: 1px solid #95a5a6;
        border-radius: 0;
        background: transparent;
        color: #2c3e50;
    }
`;

export const PreviewButton = styled.button`
    background-color: #e67e22;
    color: #ffffff;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        background-color: #d35400;
    }
`;

export const LoadFormButton = styled.button`
    background-color: #2980b9;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
    &:hover {
        background-color: #1f6392;
    }
`;

export const FormElementWrapper = styled.div`
    padding: 8px;
    background-color: transparent;
    border: none;
    position: relative;
    margin-bottom: 5px;
    box-sizing: border-box;
    word-break: break-word;
    max-width:100%;

    &.form-element-wrapper {
        /* Para poder identificarlo desde JavaScript */
    }

    input:not([type="checkbox"]):not([type="radio"]),
    select,
    textarea {
        width: 100%;
        padding: 6px;
        border: none;
        border-bottom: 1px solid #95a5a6;
        background: transparent;
        color: #000;
        max-width:100%;
        word-break: break-word;
        white-space: normal;
    }

    input[type="checkbox"],
    input[type="radio"] {
        width: auto;
        margin-right: 5px;
        cursor: pointer;
    }

    img {
        max-width: 100%;
        border-radius: 4px;
    }
`;

export const ElementHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

export const ElementHeaderHandle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    cursor: move;
    user-select: none;
`;

export const ElementHeaderActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const DeleteButton = styled.div`
    cursor: pointer;
    color: #c0392b;
    display: flex;
    align-items: center;
    &:hover {
        color: #a93226;
    }
`;

export const FavoritesIcon = styled(AiFillStar)`
    font-size: 28px;
    color: #f1c40f;
    cursor: pointer;
    &:hover {
        color: #d4ac0d;
    }
`;

export const FavoritesItem = styled.div`
    padding: 12px;
    background-color: #ecf0f1;
    border: 1px solid #95a5a6;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    color: #2c3e50;
    &:hover {
        background-color: #d0d3d4;
    }
`;

export const Modal = styled.div`
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 62, 80, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    background-color: #ecf0f1;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 4px;
    position: relative;
    @media (min-width: 768px) {
        width: 800px;
    }
    h3 {
        margin-bottom: 20px;
        color: #2c3e50;
    }
`;

export const CloseButton = styled(AiOutlineClose)`
    position: absolute;
    top: 20px;
    right: 20px;
    color: #c0392b;
    font-size: 24px;
    cursor: pointer;
    &:hover {
        color: #a93226;
    }
`;

export const DropZoneText = styled.div`
    text-align: center;
    color: #7f8c8d;
    font-size: 16px;
    pointer-events: none;
`;
