import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import axios from 'axios';

import {
    PricingContainer,
    PricingTitle,
    SubTitle,
    PlansWrapper,
    PlanCard,
    PlanHeader,
    PlanPrice,
    OriginalPrice,
    DiscountBadge,
    PlanBody,
    PlanFeatures,
    PlanFeatureItem,
    ExtrasSection,
    ExtrasTitle,
    ExtraItem,
    Separator,
    PlanFooter,
    PurchaseButton,
    PopupContainer,
    PopupContent,
    CloseButton,
    BackgroundSVGContainer
} from './PricingStyle';

import { API_BASE_URL_BILLING_SERVICE } from '../../utils/config';

const Pricing = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [plans, setPlans] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_BILLING_SERVICE}/plans`);
                setPlans(response.data);
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        };
        fetchPlans();
    }, []);

    // Función para calcular el precio con descuento
    const calcularPrecioDescuento = (precio, porcentajeDescuento) => {
        return (precio - (precio * porcentajeDescuento) / 100).toFixed(2);
    };

    // Función para obtener la oferta activa más reciente (con mayor descuento)
    const obtenerOfertaActiva = (ofertas) => {
        if (!ofertas || ofertas.length === 0) return null;

        const ahora = new Date();
        const ofertasActivas = ofertas.filter(
            (oferta) =>
                oferta.estado &&
                new Date(oferta.fechaInicio) <= ahora &&
                new Date(oferta.fechaFin) >= ahora
        );

        if (ofertasActivas.length === 0) return null;

        // Retornar la oferta con el mayor porcentaje de descuento
        return ofertasActivas.reduce((prev, current) =>
            prev.porcentajeDescuento > current.porcentajeDescuento ? prev : current
        );
    };

    // Al presionar "Comprar", generamos un objeto del plan con los precios (plan y extras) ajustados al descuento.
    const handlePurchase = (plan) => {
        // 1. Calculamos si el plan tiene una oferta activa y su precio con descuento.
        const ofertaPlan = obtenerOfertaActiva(plan.ofertas);
        const precioConDescuentoPlan = ofertaPlan
            ? calcularPrecioDescuento(plan.precio, ofertaPlan.porcentajeDescuento)
            : plan.precio;

        // 2. Hacemos lo mismo para cada extra (si existen extras).
        const extrasConPrecioDescuento = plan.extras?.map((extra) => {
            const ofertaExtra = obtenerOfertaActiva(extra.ofertas);
            const precioConDescuentoExtra = ofertaExtra
                ? calcularPrecioDescuento(extra.precio, ofertaExtra.porcentajeDescuento)
                : extra.precio;
            return {
                ...extra,
                precio: precioConDescuentoExtra
            };
        }) || [];

        // 3. Creamos un nuevo objeto de plan con los precios ajustados.
        const planConDescuentos = {
            ...plan,
            precio: precioConDescuentoPlan,
            extras: extrasConPrecioDescuento
        };

        // 4. Navegamos a /billing pasando el plan con precio(s) actualizado(s).
        navigate('/billing', { state: { plan: planConDescuentos } });
    };

    const handlePopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    // Separar planes en mensuales y anuales
    const monthlyPlans = plans.filter((plan) => plan.duracionMeses === 1);
    const annualPlans = plans.filter((plan) => plan.duracionMeses === 12);

    return (
        <div>
            <Helmet>
                <title>Precios - Vitalink</title>
                <meta
                    name="description"
                    content="Descubre nuestro plan de precios para la gestión médica eficiente con Vitalink."
                />
                <meta
                    name="keywords"
                    content="gestión médica, software médico, citas médicas, precios, servicios médicos"
                />
                <meta name="author" content="Vitalink" />
                <meta property="og:title" content="Precios - Vitalink" />
                <meta
                    property="og:description"
                    content="Descubre nuestro plan de precios para la gestión médica eficiente con Vitalink."
                />
                <meta property="og:url" content="https://www.vitalink.com/pricing" />
                <meta name="twitter:card" content="summary_large_image" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Precios - Vitalink",
                        "description":
                            "Descubre nuestro plan de precios para la gestión médica eficiente con Vitalink.",
                        "url": "https://www.vitalink.com/pricing"
                    })}
                </script>
            </Helmet>

            <Navbar />

            {/* Sección principal de Precios */}
            <PricingContainer>
                <BackgroundSVGContainer>
                    {/* SVGs decorativos */}
                    <svg
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: '300px', height: '300px', opacity: '0.2' }}
                    >
                        <defs>
                            <linearGradient id="gradient1" x1="0" x2="1" y1="1" y2="0">
                                <stop stopColor="rgba(3, 114, 255, 1)" offset="0%" />
                                <stop stopColor="rgba(151, 255, 228, 1)" offset="100%" />
                            </linearGradient>
                        </defs>
                        <path
                            fill="url(#gradient1)"
                            d="M17.6,-25.1C20.6,-22,19.3,-13.9,23.6,-6.4C27.8,1.2,37.6,8.3,38,14C38.4,19.6,29.4,23.9,21.5,27.2C13.6,30.6,6.8,33,1.5,30.9C-3.8,28.9,-7.7,22.4,-15.4,19C-23.1,15.6,-34.6,15.3,-38.3,10.8C-42,6.3,-37.9,-2.3,-33.7,-9.6C-29.4,-16.8,-25,-22.6,-19.4,-24.8C-13.8,-27.1,-6.9,-25.7,0.2,-26C7.3,-26.3,14.6,-28.2,17.6,-25.1Z"
                            width="100%"
                            height="100%"
                            transform="translate(50 50)"
                            strokeWidth="0"
                        ></path>
                    </svg>

                    <svg
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: '350px', height: '350px', top: '50%', left: '50%', opacity: '0.2' }}
                    >
                        <defs>
                            <linearGradient id="gradient2" x1="0" x2="1" y1="1" y2="0">
                                <stop stopColor="rgba(3, 114, 255, 1)" offset="0%" />
                                <stop stopColor="rgba(151, 255, 228, 1)" offset="100%" />
                            </linearGradient>
                        </defs>
                        <path
                            fill="url(#gradient2)"
                            d="M24.6,-33.6C31.4,-29,35.9,-21.1,38.7,-12.6C41.5,-4.1,42.4,5,40.1,13.3C37.7,21.5,32.1,28.9,24.8,34.9C17.6,40.8,8.8,45.3,-0.3,45.7C-9.4,46.1,-18.8,42.5,-25.5,36.3C-32.1,30.2,-36.1,21.6,-38.3,12.9C-40.5,4.2,-41,-4.6,-38.4,-12.5C-35.9,-20.4,-30.3,-27.2,-23.4,-31.8C-16.5,-36.3,-8.3,-38.5,0.3,-39C8.9,-39.4,17.9,-38.2,24.6,-33.6Z"
                            width="100%"
                            height="100%"
                            transform="translate(50 50)"
                            strokeWidth="0"
                        ></path>
                    </svg>
                </BackgroundSVGContainer>

                <PricingTitle>Precios de planes</PricingTitle>
                <SubTitle>
                    Selecciona el plan que más se ajuste a tu práctica médica y comienza a optimizar tu gestión.
                </SubTitle>

                {/* Sección de Planes Mensuales */}
                <h2>Planes Mensuales</h2>
                <PlansWrapper>
                    {monthlyPlans.map((plan) => {
                        const oferta = obtenerOfertaActiva(plan.ofertas);
                        const precioConDescuento = oferta
                            ? calcularPrecioDescuento(plan.precio, oferta.porcentajeDescuento)
                            : plan.precio;

                        return (
                            <PlanCard key={plan.id}>
                                <PlanHeader>{plan.titulo}</PlanHeader>
                                <PlanPrice>
                                    {oferta ? (
                                        <>
                                            <OriginalPrice>${plan.precio}/mes</OriginalPrice>
                                            <span> ${precioConDescuento}/mes</span>
                                            <DiscountBadge>{oferta.porcentajeDescuento}% OFF</DiscountBadge>
                                        </>
                                    ) : (
                                        <>${plan.precio}/mes</>
                                    )}
                                </PlanPrice>
                                <PlanBody>
                                    <PlanFeatures>
                                        {plan.funcionalidades &&
                                            plan.funcionalidades.map((funcionalidad) => (
                                                <PlanFeatureItem key={funcionalidad.ID_FUNCIONALIDAD}>
                                                    {funcionalidad.NOMBRE}
                                                </PlanFeatureItem>
                                            ))}
                                    </PlanFeatures>
                                    {plan.extras && plan.extras.length > 0 && (
                                        <>
                                            <Separator />
                                            <ExtrasSection>
                                                <ExtrasTitle>Extras Disponibles</ExtrasTitle>
                                                {plan.extras.map((extra) => {
                                                    const ofertaExtra = obtenerOfertaActiva(extra.ofertas);
                                                    const precioExtraConDescuento = ofertaExtra
                                                        ? calcularPrecioDescuento(extra.precio, ofertaExtra.porcentajeDescuento)
                                                        : extra.precio;

                                                    return (
                                                        <ExtraItem key={extra.id}>
                                                            {extra.nombre}{' '}
                                                            {ofertaExtra && (
                                                                <span>
                                                                    (${precioExtraConDescuento}{' '}
                                                                    <OriginalPrice>${extra.precio}</OriginalPrice>)
                                                                </span>
                                                            )}
                                                            {ofertaExtra && (
                                                                <DiscountBadge>
                                                                    {ofertaExtra.porcentajeDescuento}% OFF
                                                                </DiscountBadge>
                                                            )}
                                                        </ExtraItem>
                                                    );
                                                })}
                                            </ExtrasSection>
                                        </>
                                    )}
                                </PlanBody>
                                <PlanFooter>
                                    <PurchaseButton onClick={() => handlePurchase(plan)}>
                                        Comprar
                                    </PurchaseButton>
                                </PlanFooter>
                            </PlanCard>
                        );
                    })}
                </PlansWrapper>

                {/* Sección de Planes Anuales */}
                <h2>Planes Anuales</h2>
                <PlansWrapper>
                    {annualPlans.map((plan) => {
                        const oferta = obtenerOfertaActiva(plan.ofertas);
                        const precioConDescuento = oferta
                            ? calcularPrecioDescuento(plan.precio, oferta.porcentajeDescuento)
                            : plan.precio;

                        return (
                            <PlanCard key={plan.id}>
                                <PlanHeader>{plan.titulo}</PlanHeader>
                                <PlanPrice>
                                    {oferta ? (
                                        <>
                                            <OriginalPrice>${plan.precio}/año</OriginalPrice>
                                            <span> ${precioConDescuento}/año</span>
                                            <DiscountBadge>{oferta.porcentajeDescuento}% OFF</DiscountBadge>
                                        </>
                                    ) : (
                                        <>${plan.precio}/año</>
                                    )}
                                </PlanPrice>
                                <PlanBody>
                                    <PlanFeatures>
                                        {plan.funcionalidades &&
                                            plan.funcionalidades.map((funcionalidad) => (
                                                <PlanFeatureItem key={funcionalidad.ID_FUNCIONALIDAD}>
                                                    {funcionalidad.NOMBRE}
                                                </PlanFeatureItem>
                                            ))}
                                    </PlanFeatures>
                                    {plan.extras && plan.extras.length > 0 && (
                                        <>
                                            <Separator />
                                            <ExtrasSection>
                                                <ExtrasTitle>Extras Disponibles</ExtrasTitle>
                                                {plan.extras.map((extra) => {
                                                    const ofertaExtra = obtenerOfertaActiva(extra.ofertas);
                                                    const precioExtraConDescuento = ofertaExtra
                                                        ? calcularPrecioDescuento(extra.precio, ofertaExtra.porcentajeDescuento)
                                                        : extra.precio;

                                                    return (
                                                        <ExtraItem key={extra.id}>
                                                            {extra.nombre}{' '}
                                                            {ofertaExtra && (
                                                                <span>
                                                                    (${precioExtraConDescuento}{' '}
                                                                    <OriginalPrice>${extra.precio}</OriginalPrice>)
                                                                </span>
                                                            )}
                                                            {ofertaExtra && (
                                                                <DiscountBadge>
                                                                    {ofertaExtra.porcentajeDescuento}% OFF
                                                                </DiscountBadge>
                                                            )}
                                                        </ExtraItem>
                                                    );
                                                })}
                                            </ExtrasSection>
                                        </>
                                    )}
                                </PlanBody>
                                <PlanFooter>
                                    <PurchaseButton onClick={() => handlePurchase(plan)}>
                                        Comprar
                                    </PurchaseButton>
                                </PlanFooter>
                            </PlanCard>
                        );
                    })}
                </PlansWrapper>
            </PricingContainer>

            {/* Popup para funcionalidades en construcción, si fuera necesario */}
            {showPopup && (
                <PopupContainer>
                    <PopupContent>
                        <p>
                            Estamos trabajando en esta funcionalidad para ofrecerte el mejor servicio.
                            ¡Gracias por tu paciencia!
                        </p>
                        <CloseButton onClick={closePopup}>Cerrar</CloseButton>
                    </PopupContent>
                </PopupContainer>
            )}
        </div>
    );
};

export default Pricing;
