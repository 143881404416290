// src/Hook/useSessionVerification.js

import { useEffect, useRef } from 'react';
import { useWebSocket } from "../context/WebSocketContext";
import { toast } from 'react-toastify';

/**
 * Hook que verifica la sesión actual vía WebSocket cuando el usuario se loguea,
 * evitando repeticiones infinitas.
 * @param {boolean} isAuthenticated
 * @param {object|null} userData
 * @param {function} setIsAuthenticated
 * @param {function} logout
 */
const useSessionVerification = (isAuthenticated, userData, setIsAuthenticated, logout) => {
    const { ws } = useWebSocket();
    const hasVerifiedRef = useRef(false);

    useEffect(() => {

        if (!isAuthenticated || !userData || !ws || hasVerifiedRef.current) {
            return;
        }

        hasVerifiedRef.current = true;

        // Handler para mensajes del WebSocket
        const handleMessage = (event) => {
            try {
                const message = JSON.parse(event.data);

                if (message.type === 'DISCONNECT' && message.reason === 'MULTIPLE_SESSIONS') {
                    // Excluimos a los "usuarios excepción"
                    if (!isExceptionUser(userData)) {
                        logout();
                        toast.warn("Tu sesión ha sido cerrada porque se inició sesión en otro lugar.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            } catch (error) {
                console.error('// LOG: [useSessionVerification] -> Error al parsear mensaje WS:', error);
            }
        };

        const isExceptionUser = (user) => {
            const allowedUserIds = [314, 377];
            return allowedUserIds.includes(user.id_usuario);
        };

        ws.addEventListener('message', handleMessage);

        if (ws.readyState === WebSocket.OPEN) {
            const sessionToken = localStorage.getItem('sessionToken');
            const userId = userData.id_usuario;
            ws.send(JSON.stringify({
                type: 'VERIFY_SESSION',
                payload: { userId, sessionToken }
            }));
        }

        return () => {
            ws.removeEventListener('message', handleMessage);
        };

    }, [isAuthenticated, userData, ws]);
};

export default useSessionVerification;
