import styled from "styled-components";

export const Button = styled.button`
    background: var(--celeste);
    color: var(--blanco);
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 20px;
    width: 80%;
    cursor: pointer;
    display: block;
    margin: 3px 0;

    &:hover {
        background: var(--celeste-oscuro);
    }
`;
export const Input = styled.input`
    display: block;
    width: 100%;
    padding: 10px;
    margin: 2px 0;
    border: none;
    background: var(--gris);
    border-radius: 5px;
`;
