// frontend/components/IngresarPaciente.jsx

import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Container,
    Title,
    SearchBarContainer,
    SearchInput,
    CardsContainer,
    Card,
    CardImageWrapper,
    CardTitle,
    PreviewContainer,
    PreviewHeader,
    BackButton,
    IngresarButton,
    NoResults,
    LoadingMessage,
    StyledModal,
    ButtonAceptar,
    ButtonCancelar,
    ListItem,
    IndicadorGuardado,
    FichaHeader,
    FichaFooter,
    FichaContent
} from './IngresarPacienteStyle';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from "../../context/AuthContext";
import { API_BASE_URL } from "../../utils/config";
import { AiOutlineArrowLeft, AiOutlineSearch } from 'react-icons/ai';
import { useSede } from "../../context/SedeContext";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import html2canvas from 'html2canvas';
import { Responsive, WidthProvider } from 'react-grid-layout';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { BrandContext } from "../../context/BrandContext";

/**
 * Componente para permitir dibujar sobre una imagen (drawableImage).
 * Se asegura de que el canvas y sus trazos se incluyan en la captura (html2canvas).
 *
 * => Aquí internamente se maneja el "Limpiar", "Borrar"/"Dibujar" y cambio de color.
 *    Así evitamos errores con variables inexistentes en el padre.
 */
const DrawableCanvas = ({
                            src,
                            width = '100%',
                            height = 'auto',
                            objectFit = 'contain',
                            forwardRef
                        }) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [isErasing, setIsErasing] = useState(false);
    const [color, setColor] = useState('#ff0000');

    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    // Este contenedor define la zona que se va a capturar con html2canvas.
    const containerRef = useRef(null);

    // Inicializa el canvas
    const initCanvas = () => {
        if (!canvasRef.current || !containerRef.current) return;
        const canvas = canvasRef.current;
        const container = containerRef.current;

        // Ajustamos el tamaño del canvas al tamaño del contenedor
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;

        const ctx = canvas.getContext('2d');
        ctx.lineCap = 'round';
        ctx.lineWidth = 3;
        ctx.strokeStyle = color;
        ctx.globalCompositeOperation = isErasing ? 'destination-out' : 'source-over';
        contextRef.current = ctx;
    };

    // Si cambian color o borrado, actualizamos el contexto
    useEffect(() => {
        if (!contextRef.current) return;
        contextRef.current.strokeStyle = color;
        contextRef.current.globalCompositeOperation = isErasing ? 'destination-out' : 'source-over';
    }, [color, isErasing]);

    const startDrawing = (e) => {
        if (!contextRef.current) return;
        e.preventDefault();
        setIsDrawing(true);
        const { offsetX, offsetY } = getCursorOffset(e);
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
    };

    const draw = (e) => {
        if (!isDrawing || !contextRef.current) return;
        e.preventDefault();
        const { offsetX, offsetY } = getCursorOffset(e);
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const finishDrawing = () => {
        if (!contextRef.current) return;
        setIsDrawing(false);
        contextRef.current.closePath();
    };

    const getCursorOffset = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();

        let clientX = e.clientX;
        let clientY = e.clientY;
        if (e.touches && e.touches.length) {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        }

        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const offsetX = (clientX - rect.left) * scaleX;
        const offsetY = (clientY - rect.top) * scaleY;
        return { offsetX, offsetY };
    };

    const clearCanvas = () => {
        if (!canvasRef.current || !contextRef.current) return;
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const toggleEraser = () => {
        setIsErasing(!isErasing);
    };

    const handleImageLoad = () => {
        initCanvas();
    };

    // Exponemos containerRef para que el padre capture solamente esta sección con html2canvas
    useEffect(() => {
        if (forwardRef) {
            forwardRef.current = containerRef.current;
        }
    }, [forwardRef]);

    return (
        <div
            ref={containerRef}
            style={{
                position: 'relative',
                display: 'block',
                width
            }}
        >
            <img
                src={src}
                alt="Imagen Dibujable"
                crossOrigin="anonymous"
                style={{
                    display: 'block',
                    width: '100%',
                    height,
                    objectFit
                }}
                onLoad={handleImageLoad}
            />
            <canvas
                ref={canvasRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    cursor: isErasing ? 'crosshair' : 'default'
                }}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={finishDrawing}
                onMouseLeave={finishDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={finishDrawing}
            />
            {/* Controles de dibujo -> se incluyen aquí para que no se rompa la ref. */}
            <div
                style={{
                    marginTop: '10px',
                    zIndex: 9999,
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    position: 'relative'
                }}
            >
                <button onClick={clearCanvas} style={{ cursor: 'pointer' }}>Limpiar</button>
                <button onClick={toggleEraser} style={{ cursor: 'pointer' }}>
                    {isErasing ? 'Dibujar' : 'Borrar'}
                </button>
                <input
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    disabled={isErasing}
                    style={{
                        width: '40px',
                        height: '40px',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer'
                    }}
                />
            </div>
        </div>
    );
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const normalizeLabel = (str) => {
    if (!str) return '';
    return str
        .toLowerCase()
        .normalize("NFD").replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '_')
        .replace(/_+$/g, '');
};

const buildNormalizedJson = (formData, formElements) => {
    const result = {};
    formElements.forEach((el) => {
        const labelNormalized = el.label
            ? normalizeLabel(el.label)
            : `campo_sin_nombre_${el.id}`;
        if (el.type === 'radio' && el.groupName) {
            result[labelNormalized] = formData[el.groupName] || '';
        } else {
            result[labelNormalized] = formData[el.id];
        }
    });
    return result;
};

const IngresarPaciente = () => {
    const { userData } = useContext(AuthContext);
    const { idSedeActual } = useSede();
    const { logoUrl: fallbackLogoUrl } = useContext(BrandContext);

    const [allForms, setAllForms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const [isUserCreationModalVisible, setIsUserCreationModalVisible] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fisios, setFisios] = useState([]);
    const [selectedFisio, setSelectedFisio] = useState(null);

    const [formData, setFormData] = useState({});
    const [mostrarGuardado] = useState(false);

    // Ref solo para la parte dibujable (NO TODO el formulario):
    const drawableImageRef = useRef(null);

    const [logoError, setLogoError] = useState(false);

    const fetchAllForms = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/fichasClinicas/allForms?idSede=${idSedeActual}`);
            if (response.data.success) {
                setAllForms(response.data.fichas);
            }
        } catch (error) {
            console.error('Error fetching all forms:', error);
            toast.error('Error al obtener las fichas.');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAllForms();
        // eslint-disable-next-line
    }, [idSedeActual]);

    useEffect(() => {
        if (selectedForm) {
            // Inicializa formData
            const initialData = {};
            selectedForm.jsonData.formElements.forEach((el) => {
                if (el.type === 'checkbox') {
                    if (Array.isArray(el.options) && el.options.length > 0) {
                        initialData[el.id] = [];
                    } else {
                        initialData[el.id] = false;
                    }
                } else if (el.type === 'radio') {
                    const radioName = el.groupName || el.id;
                    initialData[radioName] = '';
                } else {
                    initialData[el.id] = '';
                }
            });
            setFormData(initialData);

            (async () => {
                try {
                    const response = await axios.get(`${API_BASE_URL}/fisio/todosLosFisios/${idSedeActual}`);
                    if (response.data.success) {
                        setFisios(response.data.fisios);
                    } else {
                        toast.error('No se pudo obtener los médicos.');
                    }
                } catch (error) {
                    console.error('Error obteniendo los fisios:', error);
                    toast.error('Error al obtener los médicos.');
                }
            })();
        }
    }, [selectedForm, idSedeActual]);

    const filteredForms = allForms.filter((f) =>
        f.title && f.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSelectForm = (form) => {
        setSelectedForm(form);
        setLogoError(false);
    };

    const handleBack = () => {
        setSelectedForm(null);
        setFormData({});
    };

    const handleIngresarClick = (e) => {
        e.preventDefault();
        setIsUserCreationModalVisible(true);
    };

    const handleUserCreationResponse = (createUser) => {
        if (createUser) {
            const requiredLabels = ['nombre', 'apellido', 'email'];
            const normalizedLabels = selectedForm.jsonData.formElements.map(el =>
                normalizeLabel(el.label || '')
            );

            const missing = requiredLabels.filter(req => !normalizedLabels.includes(req));
            if (missing.length > 0) {
                toast.error(
                    `Para crear un usuario en la plataforma, la ficha debe tener estos campos: ${missing.join(', ')}. ` +
                    'Por favor, añádelos en el diseñador dinámico antes de continuar.',
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 7000,
                        hideProgressBar: true
                    }
                );
                setIsUserCreationModalVisible(false);
                return;
            }
            setIsEmailRequired(true);
        } else {
            setIsEmailRequired(false);
        }
        setIsUserCreationModalVisible(false);
        setIsModalVisible(true);
    };

    const handleFisioSelection = (fisioId) => {
        setSelectedFisio(fisioId);
    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            const element = selectedForm?.jsonData?.formElements.find(el => el.id === name);
            const isMultipleCheckbox = element && Array.isArray(element.options) && element.options.length > 0;

            if (isMultipleCheckbox) {
                let currentVal = Array.isArray(formData[name]) ? [...formData[name]] : [];
                if (checked && !currentVal.includes(value)) {
                    currentVal.push(value);
                } else if (!checked) {
                    currentVal = currentVal.filter(v => v !== value);
                }
                setFormData(prev => ({ ...prev, [name]: currentVal }));
            } else {
                setFormData(prev => ({ ...prev, [name]: checked }));
            }
            return;
        }

        if (type === 'radio') {
            setFormData(prev => ({ ...prev, [name]: value }));
            return;
        }

        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date, id) => {
        const formatted = moment(date).format('DD/MM/YYYY');
        setFormData(prev => ({ ...prev, [id]: formatted }));
    };

    const handlePhoneChange = (phone, id) => {
        setFormData(prev => ({ ...prev, [id]: phone || '' }));
    };

    const validarYConstruirFichaJson = (fichaJsonOriginal, isEmailRequired) => {
        const normalizedFields = buildNormalizedJson(
            fichaJsonOriginal,
            selectedForm.jsonData.formElements
        );

        normalizedFields.uuidFichaClinica = selectedForm.uuid;
        normalizedFields.idSede = idSedeActual;
        normalizedFields.rol = 1;
        normalizedFields.idUsuarioEditor = userData.id_usuario;
        normalizedFields.idTipoFicha = selectedForm ? selectedForm.id : 1;
        normalizedFields.tipoCarga = 0;
        normalizedFields.idMedico = selectedFisio;

        if (normalizedFields.fecha_nac) {
            const m = moment(normalizedFields.fecha_nac, 'DD/MM/YYYY');
            if (m.isValid()) {
                normalizedFields.fecha_nac = m.format('YYYY-MM-DD');
            } else {
                normalizedFields.fecha_nac = new Date().toISOString().split('T')[0];
            }
        } else {
            normalizedFields.fecha_nac = new Date().toISOString().split('T')[0];
        }

        // Campos requeridos
        const requiredFields = ['nombre', 'apellido', 'telefono'];
        if (isEmailRequired) requiredFields.push('email');

        const erroresDeValidacion = [];
        requiredFields.forEach(fieldKey => {
            if (!normalizedFields[fieldKey] || !String(normalizedFields[fieldKey]).trim()) {
                erroresDeValidacion.push({
                    campo: fieldKey,
                    error: `El campo ${fieldKey} es requerido.`
                });
            }
        });

        if (normalizedFields.telefono && !isPossiblePhoneNumber(normalizedFields.telefono)) {
            erroresDeValidacion.push({
                campo: 'telefono',
                error: 'El número de teléfono es inválido o falta el código internacional.'
            });
        }

        if (isEmailRequired && normalizedFields.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(normalizedFields.email)) {
                erroresDeValidacion.push({
                    campo: 'email',
                    error: 'El correo electrónico es inválido.'
                });
            }
        }

        return {
            fichaJsonValidado: normalizedFields,
            erroresDeValidacion
        };
    };

    /**
     * Genera el PDF en el backend, capturando SOLO la parte dibujable (drawableImageRef).
     */
    const exportPDF = async (idUsuarioPaciente, fichaJson) => {
        try {
            // Capturamos SOLO la parte dibujable
            let bodyMapImage = null;
            if (drawableImageRef.current) {
                const canvas = await html2canvas(drawableImageRef.current, {
                    backgroundColor: null,
                    scale: 2,
                    useCORS: true
                });
                const imgData = canvas.toDataURL('image/png');
                const responseImg = await fetch(imgData);
                bodyMapImage = await responseImg.blob();
            }

            // brandData
            const brandData = {
                fallbackLogoUrl,
                primaryColor: selectedForm.jsonData?.brandData?.primaryColor || '#2980b9',
                secondaryColor: selectedForm.jsonData?.brandData?.secondaryColor || '#27ae60',
                accentColor: selectedForm.jsonData?.brandData?.accentColor || '#bdc3c7',
                slogan: selectedForm.jsonData?.brandData?.slogan || ''
            };

            // Construimos fields EXCLUYENDO "drawableImage"
            const fields = [];
            selectedForm.jsonData.formElements.forEach((el) => {
                if (el.type === 'image') {
                    fields.push({
                        isImage: true,
                        label: el.label || el.id || 'Imagen',
                        src: el.src || '',
                        style: el.style || {}
                    });
                } else if (el.type !== 'drawableImage') {
                    const label = el.label || el.id || 'Campo sin nombre';
                    let valueToShow = '';

                    if (el.type === 'checkbox' && Array.isArray(formData[el.id])) {
                        valueToShow = formData[el.id].join(', ');
                    } else if (el.type === 'radio' && el.groupName) {
                        valueToShow = formData[el.groupName];
                    } else {
                        valueToShow = formData[el.id];
                    }
                    fields.push({
                        isImage: false,
                        label,
                        value: valueToShow
                    });
                }
            });

            const pdfPayload = {
                brandData,
                fields,
                extraData: fichaJson,
                currentDate: moment().format('DD/MM/YYYY'),
                fichaTitle: selectedForm.title || 'Ficha'
            };

            const formDataSend = new FormData();
            formDataSend.append('pdfPayload', JSON.stringify(pdfPayload));
            if (bodyMapImage) {
                formDataSend.append('bodyMapImage', bodyMapImage, 'bodyMapImage.png');
            }
            formDataSend.append('idUsuarioPaciente', idUsuarioPaciente);
            formDataSend.append('tipoFicha', selectedForm?.title || 'FichaGenerica');

            const response = await axios.post(
                `${API_BASE_URL}/fichasClinicas/generarPdf`,
                formDataSend,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (response.data.success) {
                const fileUrl = response.data.fileUrl;

                // Abre el PDF en otra pestaña
                window.open(fileUrl, '_blank');

                toast.success('PDF cargado con éxito.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true
                });
            } else {
                throw new Error(response.data.message || 'Error al cargar el PDF.');
            }
        } catch (error) {
            console.error('Error al cargar el PDF:', error);
            toast.error('Error al cargar el PDF.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true
            });
        }
    };

    const handleInsert = async (e) => {
        e.preventDefault();
        setIsModalVisible(false);

        if (!selectedFisio) {
            toast.warn('Por favor, selecciona un médico.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true
            });
            return;
        }

        const { fichaJsonValidado, erroresDeValidacion } = validarYConstruirFichaJson(formData, isEmailRequired);
        if (erroresDeValidacion.length > 0) {
            erroresDeValidacion.forEach(({ error }) => {
                toast.warn(error, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true
                });
            });
            return;
        }

        try {
            const fichaJSONString = JSON.stringify(fichaJsonValidado);
            const response = await axios.post(
                `${API_BASE_URL}/paciente/insertarPaciente`,
                { fichaJson: fichaJSONString },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data && response.data.success) {
                // Generamos el PDF
                await exportPDF(response.data.idUsuario, fichaJsonValidado);

                toast.success('El paciente fue añadido exitosamente', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true
                });
            } else {
                if (response.data && response.data.error && response.data.error.includes('límite')) {
                    toast.error(
                        'Límite alcanzado: se alcanzó el límite permitido para esta sede. Consulta a tu administrador',
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: true
                        }
                    );
                } else {
                    toast.error('Error al añadir el paciente.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true
                    });
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('Error al añadir el paciente.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true
            });
        }
    };

    const renderElement = (element) => {
        const { type, label, placeholder, options, src, style, id, groupName } = element;
        const radioName = groupName || id;
        const value = formData[id] || '';

        switch (type) {
            case 'input':
            case 'text':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <input
                            type="text"
                            name={id}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                color: '#000',
                                padding: '5px 0'
                            }}
                        />
                    </div>
                );

            case 'number':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <input
                            type="number"
                            name={id}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                color: '#000',
                                padding: '5px 0'
                            }}
                        />
                    </div>
                );

            case 'email':
            case 'email2':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <input
                            type="email"
                            name={id}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                color: '#000',
                                padding: '5px 0'
                            }}
                        />
                    </div>
                );

            case 'select':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <select
                            name={id}
                            value={value}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                color: '#000',
                                padding: '5px 0'
                            }}
                        >
                            <option value="">-- Seleccione --</option>
                            {options && options.map((opt, i) => (
                                <option key={i} value={opt}>
                                    {opt}
                                </option>
                            ))}
                        </select>
                    </div>
                );

            case 'checkbox':
                if (Array.isArray(options) && options.length > 0) {
                    return (
                        <div style={{ overflow: 'visible' }}>
                            <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    gap: '20px',
                                    alignItems: 'center'
                                }}
                            >
                                {options.map((opt, i) => (
                                    <label
                                        key={i}
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            color: '#000',
                                            marginBottom: '5px'
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            name={id}
                                            value={opt}
                                            checked={Array.isArray(formData[id]) && formData[id].includes(opt)}
                                            onChange={handleChange}
                                            style={{ marginRight: '5px' }}
                                        />
                                        {opt}
                                    </label>
                                ))}
                            </div>
                        </div>
                    );
                }
                return (
                    <div style={{ overflow: 'visible' }}>
                        <label
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: '#000',
                                marginBottom: '5px'
                            }}
                        >
                            <input
                                type="checkbox"
                                name={id}
                                checked={!!formData[id]}
                                onChange={handleChange}
                                style={{ marginRight: '5px' }}
                            />
                            {label}
                        </label>
                    </div>
                );

            case 'radio':
                if (Array.isArray(options) && options.length > 0) {
                    return (
                        <div style={{ overflow: 'visible' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    gap: '20px',
                                    alignItems: 'center'
                                }}
                            >
                                {options.map((opt, i) => (
                                    <label
                                        key={i}
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            color: '#000',
                                            marginBottom: '5px'
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            name={radioName}
                                            value={opt}
                                            checked={formData[radioName] === opt}
                                            onChange={handleChange}
                                            style={{ marginRight: '5px' }}
                                        />
                                        {opt}
                                    </label>
                                ))}
                            </div>
                        </div>
                    );
                }
                return (
                    <div style={{ overflow: 'visible' }}>
                        <label
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: '#000',
                                marginBottom: '5px'
                            }}
                        >
                            <input
                                type="radio"
                                name={radioName}
                                value={label || id}
                                checked={formData[radioName] === (label || id)}
                                onChange={handleChange}
                                style={{ marginRight: '5px' }}
                            />
                            {label}
                        </label>
                    </div>
                );

            case 'image':
                return (
                    <div style={{ marginTop: '10px', overflow: 'visible' }}>
                        {src && (
                            <img
                                src={src}
                                alt="Imagen"
                                style={{
                                    display: 'block',
                                    marginTop: '5px',
                                    width: style?.width || '100%',
                                    height: style?.height || 'auto',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </div>
                );

            case 'drawableImage':
                return (
                    <div style={{ marginTop: '10px' }} ref={drawableImageRef}>
                        {src && (
                            <DrawableCanvas
                                src={src}
                                width={style?.width || '100%'}
                                height={style?.height || 'auto'}
                                objectFit="contain"
                                forwardRef={drawableImageRef}
                            />
                        )}
                    </div>
                );

            case 'phone':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <PhoneInput
                            international
                            defaultCountry="GT"
                            name={id}
                            value={formData[id] || ''}
                            onChange={(phone) => handlePhoneChange(phone, id)}
                            placeholder={placeholder || 'Número de teléfono'}
                            style={{
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                color: '#000',
                                padding: '5px 0'
                            }}
                        />
                    </div>
                );

            case 'textbox':
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <textarea
                            name={id}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                minHeight: '80px',
                                background: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #95a5a6',
                                resize: 'vertical',
                                color: '#000'
                            }}
                        />
                    </div>
                );

            case 'date': {
                const selectedDate = value ? moment(value, 'DD/MM/YYYY').toDate() : null;
                return (
                    <div style={{ overflow: 'visible' }}>
                        <div style={{ marginBottom: '5px', color: '#000' }}>{label}</div>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleDateChange(date, id)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText={placeholder || 'Seleccionar fecha'}
                            popperPlacement="top"
                            popperModifiers={[
                                {
                                    name: 'flip',
                                    enabled: false,
                                },
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        rootBoundary: 'viewport',
                                        altAxis: true,
                                        tether: false,
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 10],
                                    },
                                },
                            ]}
                            portalId="datepicker-portal"
                            className="custom-datepicker"
                        />
                    </div>
                );
            }

            case 'label':
                return (
                    <div style={{ overflow: 'hidden' }}>
                        <div
                            style={{
                                fontWeight: style?.fontWeight || 'normal',
                                color: style?.color,
                                fontSize: style?.fontSize,
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {label}
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const renderForm = () => {
        if (!selectedForm) return null;

        const { layout, formElements } = selectedForm.jsonData || {};
        if (!layout || !formElements) return null;

        const orderedLayout = [...layout].sort((a, b) => {
            if (a.y !== b.y) return a.y - b.y;
            return a.x - b.x;
        });

        const sameLayout = {
            lg: orderedLayout,
            md: orderedLayout,
            sm: orderedLayout,
            xs: orderedLayout,
            xxs: orderedLayout
        };

        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <ResponsiveGridLayout
                    className="layout"
                    layouts={sameLayout}
                    breakpoints={{ lg: 0, md: 0, sm: 0, xs: 0, xxs: 0 }}
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={30}
                    margin={[10, 10]}
                    isDraggable={false}
                    isResizable={false}
                    useCSSTransforms={true}
                    style={{
                        minHeight: '100%',
                        position: 'relative'
                    }}
                >
                    {formElements.map((element) => (
                        <div
                            key={element.id}
                            data-grid={{
                                x: element.x,
                                y: element.y,
                                w: element.w,
                                h: element.h
                            }}
                        >
                            {renderElement(element)}
                        </div>
                    ))}
                </ResponsiveGridLayout>
            </div>
        );
    };

    return (
        <Container>
            <ToastContainer />
            <Title>Ingresar Paciente</Title>

            {!selectedForm && (
                <>
                    <SearchBarContainer>
                        <AiOutlineSearch size={20} color="#888" style={{ marginRight: '8px' }} />
                        <SearchInput
                            placeholder="Buscar ficha por nombre..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </SearchBarContainer>

                    {loading && <LoadingMessage>Cargando fichas...</LoadingMessage>}

                    {!loading && filteredForms.length === 0 && (
                        <NoResults>No se encontraron fichas con ese nombre.</NoResults>
                    )}

                    <CardsContainer>
                        {filteredForms.map((form) => (
                            <Card key={form.uuid} onClick={() => handleSelectForm(form)}>
                                <CardImageWrapper>
                                    {fallbackLogoUrl ? (
                                        <img
                                            src={fallbackLogoUrl}
                                            alt="Logo Ficha (Fallback)"
                                            style={{
                                                maxHeight: '120px',
                                                maxWidth: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src="https://via.placeholder.com/300x200?text=Vista+de+Ficha"
                                            alt="Vista previa"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    )}
                                </CardImageWrapper>
                                <CardTitle>{form.title}</CardTitle>
                            </Card>
                        ))}
                    </CardsContainer>
                </>
            )}

            {selectedForm && (
                <PreviewContainer>
                    <PreviewHeader>
                        <BackButton onClick={handleBack}>
                            <AiOutlineArrowLeft size={20} /> Volver
                        </BackButton>
                    </PreviewHeader>

                    <FichaHeader>
                        {/* Logo en la esquina superior izquierda */}
                        {fallbackLogoUrl && (
                            <img
                                src={fallbackLogoUrl}
                                alt="Logo en la esquina superior izquierda"
                                style={{ maxHeight: '80px', marginRight: '15px' }}
                            />
                        )}

                        <h2
                            style={{
                                margin: 0,
                                color: '#000',
                                wordBreak: 'break-word',
                                fontSize: '24px',
                                borderBottom: '2px solid #ccc',
                                paddingBottom: '5px'
                            }}
                        >
                            {selectedForm.title}
                        </h2>
                    </FichaHeader>

                    <FichaContent>{renderForm()}</FichaContent>

                    {/* Slogan si existe */}
                    {selectedForm.jsonData?.brandData?.slogan && (
                        <FichaFooter
                            style={{
                                backgroundColor:
                                    selectedForm.jsonData.brandData.secondaryColor || '#27ae60'
                            }}
                        >
                            {selectedForm.jsonData.brandData.slogan}
                        </FichaFooter>
                    )}

                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <IngresarButton onClick={handleIngresarClick}>
                            Ingresar Paciente
                        </IngresarButton>
                    </div>
                </PreviewContainer>
            )}

            {isUserCreationModalVisible && (
                <StyledModal isOpen={isUserCreationModalVisible}>
                    <div>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                            ¿Desea crear un usuario al paciente?
                        </h2>
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center'
                            }}
                        >
                            <ButtonAceptar onClick={() => handleUserCreationResponse(true)}>
                                Sí
                            </ButtonAceptar>
                            <ButtonCancelar onClick={() => handleUserCreationResponse(false)}>
                                No
                            </ButtonCancelar>
                        </div>
                    </div>
                </StyledModal>
            )}

            {isModalVisible && (
                <StyledModal isOpen={isModalVisible}>
                    <div>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                            Seleccione un Médico
                        </h2>
                        <ul
                            style={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                padding: '0',
                                listStyle: 'none'
                            }}
                        >
                            {fisios.map((fisio) => (
                                <ListItem
                                    key={fisio.ID_USUARIO}
                                    onClick={() => handleFisioSelection(fisio.ID_USUARIO)}
                                    className={
                                        selectedFisio === fisio.ID_USUARIO ? 'selected' : ''
                                    }
                                >
                                    {fisio.NOMBRE} {fisio.APELLIDO} - {fisio.EMAIL}
                                </ListItem>
                            ))}
                        </ul>
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <ButtonAceptar onClick={handleInsert}>Aceptar</ButtonAceptar>
                            <ButtonCancelar onClick={() => setIsModalVisible(false)}>
                                Cancelar
                            </ButtonCancelar>
                        </div>
                    </div>
                </StyledModal>
            )}

            <IndicadorGuardado mostrar={mostrarGuardado} />
        </Container>
    );
};

export default IngresarPaciente;
