// useIdleTimer.js

import { useEffect, useRef } from 'react';

const useIdleTimer = (timeout = 1200000, onIdle, isDashboardRoute) => {
    const timerRef = useRef();

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        if (isDashboardRoute && onIdle) {
            timerRef.current = setTimeout(onIdle, timeout);
        }
    };

    useEffect(() => {
        if (!isDashboardRoute) return;

        const events = [
            'mousemove', 'keydown', 'scroll',
            'touchstart', 'touchmove', 'touchend',
            'click', 'wheel', 'resize',
            'input', 'change'
        ];

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer();

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [timeout, onIdle, isDashboardRoute]);

    return { resetTimer };
};

export default useIdleTimer;
