// src/context/BrandContext.jsx

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../utils/config";
import { toast } from 'react-toastify';
import { SedeContext } from "./SedeContext";

// Crear el contexto
export const BrandContext = createContext();

// Proveedor del contexto
export const BrandProvider = ({ children }) => {
    const { idSedeActual } = useContext(SedeContext);
    const [logoUrl, setLogoUrl] = useState('');
    const [colorPalette, setColorPalette] = useState({
        primaryColor: '#ffffff',
        secondaryColor: '#0372FF',
        accentColor: '#bdbdbd'
    });
    const [slogan, setSlogan] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    // Función para obtener la configuración de marca desde el backend
    const fetchConfiguracionMarca = async () => {
        if (!idSedeActual) return;
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/configuraciones/marca/${idSedeActual}`);
            if (response.data.success) {
                const { logoUrl, slogan, primaryColor, secondaryColor, accentColor } = response.data.data;
                setLogoUrl(logoUrl || '');
                setColorPalette({
                    primaryColor: primaryColor || '#ffffff',
                    secondaryColor: secondaryColor || '#0372FF',
                    accentColor: accentColor || '#bdbdbd'
                });
                setSlogan(slogan || '');
            }
        } catch (error) {
            console.error('Error al obtener la configuración de marca:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchConfiguracionMarca();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSedeActual]);

    // Función para actualizar la configuración de marca
    const actualizarConfiguracionMarca = async (nuevaConfiguracion) => {
        if (!idSedeActual) return;
        try {
            const formData = new FormData();
            formData.append('slogan', nuevaConfiguracion.slogan);
            formData.append('primaryColor', nuevaConfiguracion.colorPalette.primaryColor);
            formData.append('secondaryColor', nuevaConfiguracion.colorPalette.secondaryColor);
            formData.append('accentColor', nuevaConfiguracion.colorPalette.accentColor);

            if (nuevaConfiguracion.logoFile) {
                formData.append('logo', nuevaConfiguracion.logoFile, 'logo.png');
            }

            const response = await axios.post(`${API_BASE_URL}/configuraciones/marca/${idSedeActual}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                toast.success('Configuración de marca actualizada exitosamente.');
                // Actualizar el estado local
                setLogoUrl(response.data.data.logoUrl || nuevaConfiguracion.logoUrl);
                setColorPalette({
                    primaryColor: response.data.data.primaryColor || nuevaConfiguracion.colorPalette.primaryColor,
                    secondaryColor: response.data.data.secondaryColor || nuevaConfiguracion.colorPalette.secondaryColor,
                    accentColor: response.data.data.accentColor || nuevaConfiguracion.colorPalette.accentColor
                });
                setSlogan(response.data.data.slogan || nuevaConfiguracion.slogan);
            } else {
                toast.error('Error al actualizar la configuración de marca.');
            }
        } catch (error) {
            console.error('Error al actualizar la configuración de marca:', error);
            toast.error('Error al actualizar la configuración de marca.');
        }
    };

    return (
        <BrandContext.Provider value={{
            logoUrl,
            colorPalette,
            slogan,
            isLoading,
            actualizarConfiguracionMarca,
            fetchConfiguracionMarca
        }}>
            {children}
        </BrandContext.Provider>
    );
};
