import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import {
    BuilderContainer,
    Toolbox,
    ToolboxItem,
    Canvas,
    PropertyEditorWrapper,
    PropertyEditorField,
    TitleArea,
    TitleEditor,
    FormElementWrapper,
    FavoritesIcon,
    ModalContent,
    CloseButton,
    FavoritesItem,
    PreviewButton,
    ElementHeader,
    DropZoneText,
    DeleteButton,
    ElementHeaderHandle,
    ElementHeaderActions,
    LoadFormButton
} from './FormBuilderStyle';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-phone-number-input/style.css';

import { API_BASE_URL } from "../../utils/config";
import {
    AiOutlineFileText,
    AiOutlineCheckSquare,
    AiOutlineNumber,
    AiOutlineMail,
    AiOutlineDown,
    AiOutlinePicture,
    AiOutlineArrowsAlt,
    AiOutlinePhone,
    AiOutlineEdit,
    AiOutlineDotChart,
    AiOutlineDelete,
    AiOutlineAppstore,
    AiOutlineStar,
    AiFillDelete
} from 'react-icons/ai';

import PhoneInput from 'react-phone-number-input';
import { useSede } from "../../context/SedeContext";
import { AuthContext } from "../../context/AuthContext";
import { v4 as uuidv4 } from 'uuid';
import { StyledModal } from '../../components/Modal';

const GridLayout = WidthProvider(ReactGridLayout);

const normalizeName = (str) => {
    return str
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9]+/g, '_')
        .toLowerCase();
};

function normalizeTitleForS3(str) {
    return str
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9]+/g, '_')
        .toLowerCase();
}

function FormBuilder() {
    const { idSedeActual } = useSede();
    const { userData } = React.useContext(AuthContext);

    // Estados principales
    const [formElements, setFormElements] = useState([]);
    const [layout, setLayout] = useState([]);
    const [selectedElementId, setSelectedElementId] = useState(null);
    const [formTitle, setFormTitle] = useState('Título de la Ficha');

    const [brandData, setBrandData] = useState({
        slogan: '',
        logoUrl: '',
        primaryColor: '#2980b9',
        secondaryColor: '#27ae60',
        accentColor: '#e67e22'
    });

    const [showPreview, setShowPreview] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showAllForms, setShowAllForms] = useState(false);
    const [saveMessage, setSaveMessage] = useState('');
    const [saveError, setSaveError] = useState('');

    // Manejo de forms
    const [allForms, setAllForms] = useState([]);
    const [favoriteForms, setFavoriteForms] = useState([]);

    // Imágenes y estado
    const [imagesS3Keys, setImagesS3Keys] = useState([]);
    const [localUuid, setLocalUuid] = useState(uuidv4());
    const [currentFormId, setCurrentFormId] = useState(null);
    const [currentFormUuid, setCurrentFormUuid] = useState(localUuid);

    // Modal para eliminar
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [fichaToDelete, setFichaToDelete] = useState(null);

    // Copiado de elementos
    const [copiedElement, setCopiedElement] = useState(null);

    // Menú contextual
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        target: null,
        element: null
    });

    // Clave de localStorage
    const storageKey = React.useMemo(() => {
        if (!userData?.id_usuario || !idSedeActual) return null;
        return `editingFormState_${userData.id_usuario}_${idSedeActual}`;
    }, [userData, idSedeActual]);

    useEffect(() => {
        fetchBrandData();
    }, [idSedeActual]);

    // Al montar o cambiar storageKey, restaurar o resetear
    useEffect(() => {
        if (!storageKey) {
            resetFormState();
            return;
        }
        const savedStateString = localStorage.getItem(storageKey);
        if (savedStateString) {
            const savedState = JSON.parse(savedStateString);
            setFormTitle(savedState.formTitle || 'Título de la Ficha');
            setFormElements(savedState.formElements || []);
            setLayout(savedState.layout || []);
            setBrandData(savedState.brandData || {
                slogan: '',
                logoUrl: '',
                primaryColor: '#2980b9',
                secondaryColor: '#27ae60',
                accentColor: '#e67e22'
            });
            setImagesS3Keys(savedState.imagesS3Keys || []);
            setCurrentFormId(savedState.currentFormId || null);
            setCurrentFormUuid(savedState.currentFormUuid || uuidv4());
            setLocalUuid(savedState.currentFormUuid || uuidv4());
        } else {
            resetFormState();
        }
        // eslint-disable-next-line
    }, [storageKey]);

    // Guardar cada vez que cambia algo
    useEffect(() => {
        if (!storageKey) return;
        const editingFormState = {
            formTitle,
            formElements,
            layout,
            brandData,
            imagesS3Keys,
            currentFormId,
            currentFormUuid
        };
        localStorage.setItem(storageKey, JSON.stringify(editingFormState));
    }, [
        formTitle,
        formElements,
        layout,
        brandData,
        imagesS3Keys,
        currentFormId,
        currentFormUuid,
        storageKey
    ]);

    const resetFormState = () => {
        setFormTitle('Título de la Ficha');
        setFormElements([]);
        setLayout([]);
        setBrandData({
            slogan: '',
            logoUrl: '',
            primaryColor: '#2980b9',
            secondaryColor: '#27ae60',
            accentColor: '#e67e22'
        });
        setImagesS3Keys([]);
        setCurrentFormId(null);
        const newUuid = uuidv4();
        setCurrentFormUuid(newUuid);
        setLocalUuid(newUuid);
    };

    const fetchBrandData = async () => {
        try {
            if (!idSedeActual) return;
            const response = await axios.get(`${API_BASE_URL}/configuraciones/marca/${idSedeActual}`);
            if (response.data.success && response.data.data) {
                const { slogan, logoUrl, primaryColor, secondaryColor, accentColor } = response.data.data;
                setBrandData({ slogan, logoUrl, primaryColor, secondaryColor, accentColor });
            }
        } catch (err) {
            console.error("Error fetching brand data:", err);
        }
    };

    const fetchAllForms = async () => {
        if (!idSedeActual) return;
        try {
            const response = await axios.get(`${API_BASE_URL}/fichasClinicas/allForms?idSede=${idSedeActual}`);
            if (response.data.success) {
                setAllForms(response.data.fichas);
                const favs = response.data.fichas.filter(f => f.isFavorite);
                setFavoriteForms(favs);
            }
        } catch (error) {
            console.error("Error fetching all forms:", error);
        }
    };

    // DRAG & DROP principal
    const onDrop = (currentLayout, layoutItem, event) => {
        const type = event.dataTransfer.getData('componentType');
        if (!type) return;

        // Si layoutItem no tiene x, y, definimos
        if (!layoutItem || typeof layoutItem.x === 'undefined' || typeof layoutItem.y === 'undefined') {
            layoutItem = { x: 0, y: 0, w: 3, h: 2 };
        }

        const newElementId = `element-${Date.now()}`;
        const defaultElement = createDefaultElement(type, newElementId, layoutItem.x, layoutItem.y);

        // Insertamos en layout tal cual donde el usuario soltó
        setFormElements((prev) => [...prev, defaultElement]);
        setLayout((prev) => [
            ...prev,
            {
                i: newElementId,
                x: layoutItem.x,
                y: layoutItem.y,
                w: layoutItem.w || 3,
                h: layoutItem.h || 2,
                minW: 2,
                minH: 2
            }
        ]);
    };

    const createDefaultElement = (type, id, x = 0, y = 0) => {
        const defaultStyle = {
            fontSize: '14px',
            color: '#2c3e50',
            width: '100%',
            height: 'auto'
        };

        let element;
        switch (type) {
            case 'label':
                element = {
                    id,
                    type,
                    label: 'Texto Etiqueta',
                    style: { ...defaultStyle, fontWeight: 'normal' },
                    x, y, w: 3, h: 2, minW: 2, minH: 2
                };
                element.name = normalizeName(element.label);
                break;
            case 'checkbox':
                element = {
                    id,
                    type,
                    label: 'Etiqueta del Checkbox',
                    groupName: 'grupo-checkbox',
                    style: defaultStyle,
                    x, y, w: 3, h: 2, minW: 2, minH: 2
                };
                element.name = normalizeName(element.label);
                break;
            case 'radio':
                element = {
                    id,
                    type,
                    label: 'Etiqueta del Radio',
                    groupName: 'grupo-radio',
                    style: defaultStyle,
                    x, y, w: 3, h: 2, minW: 2, minH: 2
                };
                element.name = normalizeName(element.label);
                break;
            default:
                element = {
                    id,
                    type,
                    label: 'Etiqueta',
                    placeholder: '',
                    style: defaultStyle,
                    x, y,
                    w: 3,
                    h: (type === 'textbox') ? 3 : 2,
                    minW: 2,
                    minH: 2
                };
                if (type === 'select') {
                    element.options = ['Opción 1', 'Opción 2'];
                }
                element.name = normalizeName(element.label);
                break;
        }
        return element;
    };

    const updateFormElement = (elementId, newProperties) => {
        setFormElements((prevElements) =>
            prevElements.map((el) => {
                if (el.id === elementId) {
                    const updated = { ...el, ...newProperties };
                    if (newProperties.label !== undefined) {
                        updated.name = normalizeName(newProperties.label);
                    }
                    return updated;
                }
                return el;
            })
        );
    };

    const deleteElement = (elementId) => {
        setFormElements((prev) => prev.filter((el) => el.id !== elementId));
        setLayout((prev) => prev.filter((l) => l.i !== elementId));
        if (selectedElementId === elementId) {
            setSelectedElementId(null);
        }
    };

    const generateFormJSON = () => {
        return {
            title: formTitle,
            layout,
            formElements,
            brandData
        };
    };

    const togglePreview = () => setShowPreview(!showPreview);
    const toggleFavorites = () => setShowFavorites(!showFavorites);

    const toggleAllForms = async () => {
        if (!showAllForms) {
            await fetchAllForms();
        }
        setShowAllForms(!showAllForms);
    };

    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
        setFormElements((prevElements) =>
            prevElements.map((el) => {
                const l = newLayout.find((item) => item.i === el.id);
                if (l) {
                    return { ...el, x: l.x, y: l.y, w: l.w, h: l.h };
                }
                return el;
            })
        );
    };

    // ➜ Se elimina la asignación directa del layout en onDragStop para evitar bloqueos en espacios vacíos
    // const onDragStop = (newLayout) => {
    //     setLayout([...newLayout]);
    // };

    const handleSendToBackend = async () => {
        setSaveMessage('');
        setSaveError('');
        if (!idSedeActual) {
            setSaveError('No hay sede seleccionada, no se puede guardar.');
            return;
        }
        const formJSON = generateFormJSON();

        try {
            let response;
            if (currentFormId) {
                response = await axios.put(
                    `${API_BASE_URL}/fichasClinicas/ficha/updateFichaClinica/${currentFormId}`,
                    {
                        title: formTitle,
                        jsonData: formJSON,
                        isFavorite: false,
                        imagesS3Keys
                    }
                );
            } else {
                response = await axios.post(`${API_BASE_URL}/fichasClinicas/ficha/saveFichaClinica`, {
                    idSede: idSedeActual,
                    title: formTitle,
                    jsonData: formJSON,
                    isFavorite: false,
                    imagesS3Keys,
                    uuid: currentFormUuid
                });
            }

            if (response.data.success) {
                setSaveMessage('Ficha guardada con éxito.');
                const savedFicha = response.data.ficha;
                setCurrentFormId(savedFicha.ID_FICHA);
                setCurrentFormUuid(savedFicha.UUID);
                setLocalUuid(savedFicha.UUID);
            } else {
                setSaveError('No se pudo guardar la ficha en la base de datos.');
            }
        } catch (error) {
            console.error('Error al guardar ficha clínica en BD:', error);
            setSaveError('Ocurrió un error al guardar en la base de datos.');
        }
    };

    const uploadImageToS3 = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('idSede', idSedeActual);
        formData.append('uuid', currentFormUuid);
        formData.append('title', formTitle);

        try {
            const response = await axios.post(`${API_BASE_URL}/fichasClinicas/uploadImage`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.success) {
                setImagesS3Keys((prev) => [...prev, response.data.key]);
                return response.data.fileUrl;
            } else {
                throw new Error(response.data.message || 'Error al subir imagen');
            }
        } catch (error) {
            console.error('Error subiendo imagen:', error);
            throw error;
        }
    };

    const loadFormFromAll = (ficha) => {
        setFormTitle(ficha.title);
        setFormElements(ficha.jsonData.formElements || []);
        setLayout(ficha.jsonData.layout || []);
        if (ficha.jsonData.brandData) {
            setBrandData(ficha.jsonData.brandData);
        }
        setImagesS3Keys(ficha.imagesS3Keys || []);
        setCurrentFormId(ficha.id);
        setCurrentFormUuid(ficha.uuid);
        setLocalUuid(ficha.uuid);
        setShowAllForms(false);
    };

    const copyFicha = (ficha) => {
        setFormTitle(ficha.title + ' (Copia)');
        setFormElements(ficha.jsonData.formElements || []);
        setLayout(ficha.jsonData.layout || []);
        if (ficha.jsonData.brandData) {
            setBrandData(ficha.jsonData.brandData);
        } else {
            setBrandData({
                slogan: '',
                logoUrl: '',
                primaryColor: '#2980b9',
                secondaryColor: '#27ae60',
                accentColor: '#e67e22'
            });
        }
        setImagesS3Keys(ficha.imagesS3Keys || []);
        setCurrentFormId(null);
        const newUUID = uuidv4();
        setCurrentFormUuid(newUUID);
        setLocalUuid(newUUID);
        setShowAllForms(false);
    };

    const handleToggleFavorite = async (ficha) => {
        try {
            const newVal = !ficha.isFavorite;
            const response = await axios.patch(
                `${API_BASE_URL}/fichasClinicas/ficha/updateFavorite/${ficha.id}`,
                { isFavorite: newVal }
            );
            if (response.data.success) {
                await fetchAllForms();
            }
        } catch (error) {
            console.error("Error al marcar favorito:", error);
        }
    };

    const openDeleteModal = (fichaId) => {
        setFichaToDelete(fichaId);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setFichaToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const confirmDeleteFicha = async () => {
        if (!fichaToDelete) return;
        try {
            const response = await axios.delete(`${API_BASE_URL}/fichasClinicas/ficha/deleteFichaClinica/${fichaToDelete}`);
            if (response.data.success) {
                alert('Ficha clínica eliminada exitosamente.');
                await fetchAllForms();
            } else {
                alert('No se pudo eliminar la ficha clínica.');
            }
        } catch (error) {
            console.error("Error al eliminar ficha clínica:", error);
            alert('Ocurrió un error al eliminar la ficha clínica.');
        } finally {
            closeDeleteModal();
        }
    };

    const onContextMenuCanvas = (e) => {
        const path = e.composedPath ? e.composedPath() : e.path || [];
        const isElement = path.some((node) => {
            if (!node || !node.classList) return false;
            return node.classList.contains('form-element-wrapper');
        });

        e.preventDefault();
        e.stopPropagation();

        if (!isElement) {
            setContextMenu({
                visible: true,
                x: e.clientX,
                y: e.clientY,
                target: 'canvas',
                element: null
            });
        }
    };

    const openContextMenuForElement = (e, element) => {
        e.preventDefault();
        e.stopPropagation();
        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY,
            target: 'element',
            element
        });
    };

    const closeContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0, target: null, element: null });
    };

    const handleCopyElement = (element) => {
        setCopiedElement(element);
        closeContextMenu();
    };

    const handlePasteElement = (posY = 1) => {
        if (!copiedElement) return;
        const newElementId = `element-${Date.now()}`;
        const clonedElement = { ...copiedElement, id: newElementId };
        const newX = copiedElement.x || 0;
        const newY = (copiedElement.y || 0) + posY;

        setFormElements((prev) => [...prev, { ...clonedElement, x: newX, y: newY }]);
        setLayout((prev) => [
            ...prev,
            {
                i: newElementId,
                x: newX,
                y: newY,
                w: copiedElement.w || 3,
                h: copiedElement.h || 2,
                minW: copiedElement.minW || 2,
                minH: copiedElement.minH || 2
            }
        ]);
        closeContextMenu();
    };

    return (
        <BuilderContainer
            onClick={() => {
                if (contextMenu.visible) {
                    closeContextMenu();
                }
            }}
        >
            <Toolbox>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0
                    }}
                >
                    <h3>Herramientas</h3>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <FavoritesIcon
                            onClick={async () => {
                                await fetchAllForms();
                                setShowFavorites(!showFavorites);
                            }}
                            title="Ver Favoritos"
                        />
                        <AiOutlineAppstore
                            style={{ cursor: 'pointer', color: '#f1c40f' }}
                            onClick={toggleAllForms}
                            title="Todos mis Formularios"
                            size={24}
                        />
                    </div>
                </div>

                {showFavorites && (
                    <StyledModal
                        isOpen={showFavorites}
                        onRequestClose={() => setShowFavorites(false)}
                        width="600px"
                        maxWidth="90%"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <ModalContent
                            style={{
                                position: 'relative',
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}
                        >
                            <CloseButton onClick={() => setShowFavorites(false)} />
                            <h3>Mis Favoritos</h3>
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {favoriteForms.length === 0 && (
                                    <p style={{ textAlign: 'center', color: '#999' }}>
                                        No tienes fichas marcadas como favoritas.
                                    </p>
                                )}
                                {favoriteForms.map((ficha) => (
                                    <div
                                        key={ficha.uuid}
                                        style={{
                                            border: '1px solid #ccc',
                                            marginBottom: '10px',
                                            padding: '10px',
                                            position: 'relative',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            loadFormFromAll(ficha);
                                            setShowFavorites(false);
                                        }}
                                    >
                                        <h4>{ficha.title}</h4>
                                        <p>{new Date(ficha.createdAt).toLocaleString()}</p>
                                        <p>{ficha.jsonData.formElements.length} elementos</p>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                display: 'flex',
                                                gap: '5px'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggleFavorite(ficha);
                                            }}
                                        >
                                            {ficha.isFavorite ? (
                                                <AiOutlineStar size={24} color="#f1c40f" />
                                            ) : (
                                                <AiOutlineStar size={24} color="#333" />
                                            )}
                                            <AiFillDelete
                                                size={20}
                                                color="#e74c3c"
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openDeleteModal(ficha.id);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ModalContent>
                    </StyledModal>
                )}

                {showAllForms && (
                    <StyledModal
                        isOpen={showAllForms}
                        onRequestClose={toggleAllForms}
                        width="800px"
                        maxWidth="95%"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <ModalContent
                            style={{
                                position: 'relative',
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}
                        >
                            <CloseButton onClick={toggleAllForms} />
                            <h3>Todos mis Formularios</h3>
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {allForms.length === 0 && (
                                    <p style={{ textAlign: 'center', color: '#999' }}>
                                        No tienes fichas guardadas.
                                    </p>
                                )}
                                {allForms.map((ficha) => (
                                    <div
                                        key={ficha.uuid}
                                        style={{
                                            border: '1px solid #ccc',
                                            marginBottom: '10px',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            position: 'relative'
                                        }}
                                        onClick={() => {
                                            loadFormFromAll(ficha);
                                        }}
                                    >
                                        <h4>{ficha.title}</h4>
                                        <p>Creado: {new Date(ficha.createdAt).toLocaleString()}</p>
                                        <p>{ficha.jsonData.formElements.length} elementos</p>

                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                display: 'flex',
                                                gap: '5px'
                                            }}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleToggleFavorite(ficha);
                                                }}
                                            >
                                                {ficha.isFavorite ? (
                                                    <AiOutlineStar size={24} color="#f1c40f" />
                                                ) : (
                                                    <AiOutlineStar size={24} color="#333" />
                                                )}
                                            </div>
                                            <AiFillDelete
                                                size={20}
                                                color="#e74c3c"
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openDeleteModal(ficha.id);
                                                }}
                                            />
                                        </div>

                                        <LoadFormButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                loadFormFromAll(ficha);
                                            }}
                                        >
                                            Cargar este Form
                                        </LoadFormButton>

                                        <LoadFormButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                copyFicha(ficha);
                                            }}
                                            style={{ backgroundColor: '#8e44ad', marginLeft: '10px' }}
                                        >
                                            Copiar Ficha
                                        </LoadFormButton>
                                    </div>
                                ))}
                            </div>
                        </ModalContent>
                    </StyledModal>
                )}

                <DraggableToolboxItem type="input" label="Texto" icon={<AiOutlineFileText />} />
                <DraggableToolboxItem type="checkbox" label="Checkbox" icon={<AiOutlineCheckSquare />} />
                <DraggableToolboxItem type="number" label="Número" icon={<AiOutlineNumber />} />
                <DraggableToolboxItem type="email" label="Email" icon={<AiOutlineMail />} />
                <DraggableToolboxItem type="email2" label="Correo Secundario" icon={<AiOutlineMail />} />
                <DraggableToolboxItem type="select" label="Selección" icon={<AiOutlineDown />} />
                <DraggableToolboxItem type="image" label="Imagen" icon={<AiOutlinePicture />} />
                <DraggableToolboxItem type="drawableImage" label="Imagen Dibujable" icon={<AiOutlineEdit />} />
                <DraggableToolboxItem type="phone" label="Teléfono" icon={<AiOutlinePhone />} />
                <DraggableToolboxItem type="radio" label="Radio" icon={<AiOutlineDotChart />} />
                <DraggableToolboxItem type="textbox" label="Caja de texto grande" icon={<AiOutlineFileText />} />
                <DraggableToolboxItem type="date" label="Fecha" icon={<AiOutlineFileText />} />
                <DraggableToolboxItem type="label" label="Texto Etiqueta" icon={<AiOutlineFileText />} />

                <PreviewButton onClick={togglePreview}>Vista Previa</PreviewButton>
                <PreviewButton onClick={handleSendToBackend} style={{ marginTop: '10px' }}>
                    Guardar
                </PreviewButton>

                {saveMessage && (
                    <div style={{ color: 'green', marginTop: '10px' }}>{saveMessage}</div>
                )}
                {saveError && (
                    <div style={{ color: 'red', marginTop: '10px' }}>{saveError}</div>
                )}
            </Toolbox>

            <CanvasArea
                formTitle={formTitle}
                setFormTitle={setFormTitle}
                formElements={formElements}
                selectedElementId={selectedElementId}
                setSelectedElementId={setSelectedElementId}
                layout={layout}
                onLayoutChange={onLayoutChange}
                onDrop={onDrop}
                deleteElement={deleteElement}
                // Se quita onDragStop
                isPreview={showPreview}
                onContextMenuCanvas={onContextMenuCanvas}
                openContextMenuForElement={openContextMenuForElement}
            />

            <PropertyEditor
                element={formElements.find((el) => el.id === selectedElementId)}
                updateElement={(updatedProperties) => {
                    updateFormElement(selectedElementId, updatedProperties);
                }}
                brandData={brandData}
                onUploadImage={uploadImageToS3}
            />

            {showPreview && (
                <StyledModal
                    isOpen={showPreview}
                    onRequestClose={togglePreview}
                    width="80%"
                    maxWidth="1200px"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflowX: 'hidden'
                    }}
                >
                    <ModalContent
                        style={{
                            width: '100%',
                            maxHeight: '80vh',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            borderRadius: '8px',
                            position: 'relative'
                        }}
                    >
                        <CloseButton
                            onClick={togglePreview}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 9999,
                                color: '#333',
                                cursor: 'pointer'
                            }}
                        />
                        <FormPreview
                            formJSON={generateFormJSON()}
                            brandData={brandData}
                            formTitle={formTitle}
                        />
                    </ModalContent>
                </StyledModal>
            )}

            <StyledModal
                isOpen={isDeleteModalOpen}
                onRequestClose={closeDeleteModal}
                width="400px"
                maxWidth="90%"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ModalContent style={{ position: 'relative' }}>
                    <h3>Confirmar Eliminación</h3>
                    <p>¿Estás seguro de que deseas eliminar esta ficha clínica? Esta acción no se puede deshacer.</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                        <button
                            onClick={closeDeleteModal}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#3498db',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Cerrar
                        </button>
                        <button
                            onClick={confirmDeleteFicha}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#e74c3c',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Eliminar
                        </button>
                    </div>
                </ModalContent>
            </StyledModal>

            {contextMenu.visible && (
                <div
                    style={{
                        position: 'fixed',
                        top: contextMenu.y,
                        left: contextMenu.x,
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        zIndex: 999999,
                        padding: '8px'
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {contextMenu.target === 'element' && (
                        <div
                            style={{
                                cursor: 'pointer',
                                padding: '4px 8px'
                            }}
                            onClick={() => handleCopyElement(contextMenu.element)}
                        >
                            Copiar elemento
                        </div>
                    )}
                    <div
                        style={{
                            cursor: copiedElement ? 'pointer' : 'not-allowed',
                            opacity: copiedElement ? 1 : 0.5,
                            padding: '4px 8px'
                        }}
                        onClick={() => {
                            if (copiedElement) {
                                handlePasteElement();
                            }
                        }}
                    >
                        Pegar elemento
                    </div>
                </div>
            )}
        </BuilderContainer>
    );
}

const DraggableToolboxItem = ({ type, label, icon }) => {
    const onDragStart = (e) => {
        e.dataTransfer.setData('componentType', type);
    };
    return (
        <ToolboxItem
            draggable
            onDragStart={onDragStart}
            title={`Añadir ${label}`}
        >
            {icon} {label}
        </ToolboxItem>
    );
};

const CanvasArea = ({
                        formTitle,
                        setFormTitle,
                        formElements,
                        selectedElementId,
                        setSelectedElementId,
                        layout,
                        onLayoutChange,
                        onDrop,
                        deleteElement,
                        // onDragStop se ha removido
                        isPreview,
                        onContextMenuCanvas,
                        openContextMenuForElement
                    }) => {
    return (
        <Canvas
            style={{
                position: 'relative',
                flexGrow: 1,
                maxHeight: 'calc(100vh - 0px)',
                overflowY: 'auto'
            }}
            onContextMenu={onContextMenuCanvas}
        >
            <TitleArea>
                <TitleEditor
                    value={formTitle}
                    onChange={(e) => setFormTitle(e.target.value)}
                    placeholder="Título de la Ficha"
                    style={{
                        color: '#000',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal'
                    }}
                />
            </TitleArea>

            <div
                style={{
                    maxWidth: '1000px',
                    width: '100%',
                    margin: '0 auto',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    minHeight: '600px',
                    position: 'relative',
                    backgroundColor: '#f9f9f9',
                    boxSizing: 'border-box'
                }}
            >
                <GridLayout
                    className="layout"
                    layout={layout}
                    cols={12}
                    margin={[5, 5]}
                    rowHeight={30}
                    // Ajustes importantes:
                    compactType={null}
                    preventCollision={true}
                    // Quitar allowOverlap o ponerlo en false, pero que no se compacte:
                    isResizable
                    isDraggable={!isPreview}
                    useCSSTransforms={true}
                    isDroppable={!isPreview}
                    onDrop={onDrop}
                    droppingItem={{ w: 3, h: 2 }}
                    onDragOver={(e) => e.preventDefault()}
                    onLayoutChange={onLayoutChange}
                    draggableHandle=".element-drag-handle"
                    style={{
                        minHeight: '600px',
                        position: 'relative',
                        overflow: 'visible',
                        width: '100%',
                        boxSizing: 'border-box'
                    }}
                >
                    {formElements.map((element) => (
                        <div
                            key={element.id}
                            data-grid={{
                                x: element.x,
                                y: element.y,
                                w: element.w,
                                h: element.h,
                                minW: element.minW,
                                minH: element.minH
                            }}
                        >
                            <FormElement
                                element={element}
                                isSelected={selectedElementId === element.id}
                                onClick={() => !isPreview && setSelectedElementId(element.id)}
                                onDelete={() => !isPreview && deleteElement(element.id)}
                                isPreview={isPreview}
                                openContextMenuForElement={openContextMenuForElement}
                            />
                        </div>
                    ))}
                </GridLayout>

                {formElements.length === 0 && (
                    <DropZoneText
                        style={{
                            pointerEvents: 'none',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        Arrastra aquí para agregar elementos
                    </DropZoneText>
                )}
            </div>
        </Canvas>
    );
};

const FormElement = ({
                         element,
                         isSelected,
                         onClick,
                         onDelete,
                         isPreview,
                         openContextMenuForElement
                     }) => {
    return (
        <FormElementWrapper
            className="form-element-wrapper"
            isSelected={isSelected}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
            onContextMenu={(e) => openContextMenuForElement(e, element)}
            style={{
                fontSize: element.style.fontSize,
                color: '#000',
                marginBottom: '5px',
                overflow: 'hidden'
            }}
        >
            {!isPreview && element.type !== 'shape' && (
                <ElementHeader>
                    <ElementHeaderHandle className="element-drag-handle">
                        <AiOutlineArrowsAlt style={{ marginRight: '10px' }} />
                    </ElementHeaderHandle>
                    <ElementHeaderActions>
                        <DeleteButton
                            title="Eliminar"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete();
                            }}
                        >
                            <AiOutlineDelete />
                        </DeleteButton>
                    </ElementHeaderActions>
                </ElementHeader>
            )}
            {renderElement(element, isPreview)}
        </FormElementWrapper>
    );
};

const renderElement = (element, isPreview = false) => {
    const { label, style, type, groupName, placeholder, src, options } = element;
    const canInteract = isPreview;

    const inputProps = {
        placeholder,
        readOnly: !canInteract,
        style: {
            width: '100%',
            background: 'transparent',
            border: 'none',
            borderBottom: '1px solid #95a5a6',
            color: '#000',
            overflow: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'normal'
        }
    };

    switch (type) {
        case 'input':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <input type="text" {...inputProps} />
                </div>
            );
        case 'checkbox':
            return (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        name={groupName || 'checkboxGroup'}
                        disabled={!canInteract}
                        style={{ marginRight: '5px' }}
                    />
                    <span>{label}</span>
                </div>
            );
        case 'number':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <input type="number" {...inputProps} />
                </div>
            );
        case 'email':
        case 'email2':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <input type="email" {...inputProps} />
                </div>
            );
        case 'select':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <select
                        disabled={!canInteract}
                        style={{
                            width: '100%',
                            background: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #95a5a6',
                            color: '#000'
                        }}
                    >
                        {Array.isArray(options) && options.map((opt, idx) => (
                            <option key={idx} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </select>
                </div>
            );
        case 'image':
            return (
                <div style={{ overflow: 'hidden' }}>
                    {src && (
                        <img
                            src={src}
                            alt="Imagen"
                            style={{
                                display: 'block',
                                marginTop: '5px',
                                width: style.width || '100%',
                                height: style.height || 'auto',
                                objectFit: 'contain'
                            }}
                        />
                    )}
                </div>
            );
        case 'drawableImage':
            return (
                <div style={{ overflow: 'hidden' }}>
                    {src && (
                        <div style={{ position: 'relative', marginTop: '5px' }}>
                            <img
                                src={src}
                                alt="Imagen Dibujable"
                                style={{
                                    display: 'block',
                                    width: style.width || '100%',
                                    height: style.height || 'auto',
                                    objectFit: 'contain'
                                }}
                            />
                            <canvas
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    pointerEvents: 'none'
                                }}
                            />
                        </div>
                    )}
                </div>
            );
        case 'phone':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <PhoneInput
                        defaultCountry="GT"
                        placeholder={placeholder}
                        disabled={!canInteract}
                        onChange={() => {}}
                        style={{
                            width: '100%',
                            background: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #95a5a6',
                            padding: '5px 0',
                            color: '#000'
                        }}
                    />
                </div>
            );
        case 'radio':
            return (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <input
                        type="radio"
                        name={groupName || 'radioGroup'}
                        disabled={!canInteract}
                        style={{ marginRight: '5px' }}
                    />
                    <span>{label}</span>
                </div>
            );
        case 'textbox':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <textarea
                        placeholder={placeholder}
                        readOnly={!canInteract}
                        style={{
                            width: '100%',
                            height: '100px',
                            background: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #95a5a6',
                            resize: 'vertical',
                            color: '#000'
                        }}
                    />
                </div>
            );
        case 'date':
            return (
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ marginBottom: '5px' }}>{label}</div>
                    <input
                        type="text"
                        readOnly
                        placeholder={placeholder}
                        style={{
                            width: '100%',
                            background: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #95a5a6',
                            color: '#000'
                        }}
                        value="dd/MM/yyyy"
                    />
                </div>
            );
        case 'label':
            return (
                <div
                    style={{
                        fontWeight: style.fontWeight || 'normal',
                        color: style.color,
                        fontSize: style.fontSize,
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {label}
                </div>
            );
        default:
            return null;
    }
};

const PropertyEditor = ({ element, updateElement, brandData, onUploadImage }) => {
    if (!element) {
        return (
            <PropertyEditorWrapper>
                <h3>Propiedades</h3>
                <p>Seleccione un elemento para editar sus propiedades.</p>
            </PropertyEditorWrapper>
        );
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateElement({ [name]: value });
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const fileUrl = await onUploadImage(file);
                updateElement({ src: fileUrl });
            } catch (error) {
                console.error('Error subiendo imagen:', error);
                alert('Error al subir imagen');
            }
        }
    };

    const { type, label, style, placeholder, groupName, options, src } = element;

    return (
        <PropertyEditorWrapper>
            <h3>Propiedades</h3>
            <PropertyEditorField>
                <label>Etiqueta:</label>
                <input
                    type="text"
                    name="label"
                    value={label}
                    onChange={handleChange}
                />
            </PropertyEditorField>

            {type !== 'label' && type !== 'checkbox' && type !== 'radio' && (
                <PropertyEditorField>
                    <label>Placeholder:</label>
                    <input
                        type="text"
                        name="placeholder"
                        value={placeholder || ''}
                        onChange={handleChange}
                    />
                </PropertyEditorField>
            )}

            {(type === 'checkbox' || type === 'radio') && (
                <PropertyEditorField>
                    <label>Group Name:</label>
                    <input
                        type="text"
                        name="groupName"
                        value={groupName || ''}
                        onChange={handleChange}
                    />
                </PropertyEditorField>
            )}

            {type === 'select' && (
                <PropertyEditorField>
                    <label>Opciones (separadas por coma):</label>
                    <input
                        type="text"
                        value={Array.isArray(options) ? options.join(', ') : ''}
                        onChange={(e) => {
                            const newOptions = e.target.value.split(',').map(opt => opt.trim());
                            updateElement({ options: newOptions });
                        }}
                    />
                </PropertyEditorField>
            )}

            {(type === 'image' || type === 'drawableImage') && (
                <>
                    <PropertyEditorField>
                        <label>Ancho (ej. 100px o 100%):</label>
                        <input
                            type="text"
                            name="style.width"
                            value={style.width || ''}
                            onChange={(e) => {
                                updateElement({
                                    style: {
                                        ...style,
                                        width: e.target.value
                                    }
                                });
                            }}
                        />
                    </PropertyEditorField>
                    <PropertyEditorField>
                        <label>Alto (ej. auto o 200px):</label>
                        <input
                            type="text"
                            name="style.height"
                            value={style.height || ''}
                            onChange={(e) => {
                                updateElement({
                                    style: {
                                        ...style,
                                        height: e.target.value
                                    }
                                });
                            }}
                        />
                    </PropertyEditorField>
                    <PropertyEditorField>
                        <label>Imagen:</label>
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                        {src && <p>Imagen cargada</p>}
                    </PropertyEditorField>
                </>
            )}

            <h3>Estilo</h3>
            <PropertyEditorField>
                <label>Tamaño de letra (px):</label>
                <input
                    type="number"
                    name="style.fontSize"
                    value={parseInt(style.fontSize, 10)}
                    onChange={(e) => {
                        updateElement({
                            style: {
                                ...style,
                                fontSize: e.target.value + 'px'
                            }
                        });
                    }}
                />
            </PropertyEditorField>
            <PropertyEditorField>
                <label>Color del texto:</label>
                <input
                    type="color"
                    value={style.color || '#2c3e50'}
                    onChange={(e) => {
                        updateElement({
                            style: {
                                ...style,
                                color: e.target.value
                            }
                        });
                    }}
                />
            </PropertyEditorField>

            {type === 'label' && (
                <PropertyEditorField>
                    <label
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={style.fontWeight === 'bold'}
                            onChange={(e) => {
                                updateElement({
                                    style: {
                                        ...style,
                                        fontWeight: e.target.checked
                                            ? 'bold'
                                            : 'normal'
                                    }
                                });
                            }}
                        />
                        Negrita
                    </label>
                </PropertyEditorField>
            )}
        </PropertyEditorWrapper>
    );
};

const FormPreview = ({ formJSON, brandData, formTitle }) => {
    if (!formJSON) return null;

    const { layout, formElements } = formJSON;

    return (
        <div
            style={{
                width: '100%',
                flex: 1,
                backgroundColor: brandData.primaryColor || '#2980b9',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxSizing: 'border-box',
                overflowX: 'hidden'
            }}
        >
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#000'
                }}
            >
                {brandData.logoUrl && (
                    <img
                        src={brandData.logoUrl}
                        alt="Logo"
                        style={{ maxHeight: '80px', marginRight: '10px' }}
                    />
                )}
                <h2
                    style={{
                        margin: 0,
                        color: '#000',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal'
                    }}
                >
                    {formTitle}
                </h2>
            </div>

            <div
                style={{
                    width: '90%',
                    maxWidth: '900px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#ecf0f1',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'relative',
                        maxHeight: 'calc(80vh - 120px)'
                    }}
                >
                    <GridLayout
                        className="layout"
                        layout={layout}
                        cols={12}
                        margin={[5, 5]}
                        rowHeight={30}
                        isResizable={false}
                        isDraggable={false}
                        compactType={null} // Para que no compacte en la vista previa
                        preventCollision={true}
                        allowOverlap={false}
                        style={{
                            minHeight: '500px',
                            position: 'relative',
                            maxWidth: '100%'
                        }}
                    >
                        {formElements.map((element) => (
                            <div
                                key={element.id}
                                data-grid={{
                                    x: element.x,
                                    y: element.y,
                                    w: element.w,
                                    h: element.h
                                }}
                            >
                                <FormElementWrapper
                                    style={{
                                        fontSize: element.style.fontSize,
                                        color: '#000',
                                        marginBottom: '5px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {renderElement(element, true)}
                                </FormElementWrapper>
                            </div>
                        ))}
                    </GridLayout>
                </div>

                {brandData.slogan && (
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            backgroundColor: brandData.secondaryColor || '#27ae60',
                            padding: '10px 0',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal'
                        }}
                    >
                        {brandData.slogan}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormBuilder;
