import React, { useState, useContext, createContext, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useWebSocket } from "../context/WebSocketContext";
import { useSede } from '../context/SedeContext';
import { SidebarContext } from "../context/SidebarContext";

import {
  Sidebar,
  UserInfo,
  Menu,
  MenuItem,
  SubMenu,
  SubMenuItem,
  ChevronIcon,
  SidebarButton,
  Sede,
  NotificationBellContainer,
  SidebarLogoContainer,
  SidebarLogo,
  Tooltip,
  PopoutContainer
} from '../views/Dashboard/DashboardStyle';

import defaultProfilePicture from '../assets/login/profile/default.png';
import HeartIconAnimation from "./HeartIconAnimation/HeartIconAnimation";
import heartAnimationData from './HeartIconAnimation/AnimationHeart.json';
import animationData from '../assets/profile.json';
import axios from "axios";
import { FaBars, FaTimes, FaUsersCog, FaUserMd, FaUserInjured, FaChartLine, FaCog, FaBusinessTime, FaTools, FaComments } from 'react-icons/fa';

import Lottie from 'react-lottie';
import NotificationBell from "./NotificationBell/NotificationBell";
import { API_BASE_URL } from "../utils/config";

import NotificationsSlider from '../components/NotificationsSlider/NotificationsSlider';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { BrandContext } from "../context/BrandContext";

export const LayoutContext = createContext({
  activeSubMenu: '',
  setActiveSubMenu: () => {},
  handleNavigate: () => {}
});

const LayoutSide = ({ children }) => {

  const { sidebarState } = useContext(SidebarContext);
  const { userData, logout } = useContext(AuthContext);
  const { nombreSedeActual } = useSede();
  const wsContext = useWebSocket();
  const { logoUrl } = useContext(BrandContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [activeSubMenu, setActiveSubMenu] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Para móvil (drawer)
  const [animationKey, setAnimationKey] = useState(0);
  const [notificationCount] = useState(5);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Para manejar el tooltip
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Para manejar popouts (submenús en modo colapsado)
  const [popoutSubMenu, setPopoutSubMenu] = useState('');
  const [popoutPosition, setPopoutPosition] = useState({ x: 0, y: 0 });

  // Determina si es móvil
  const isMobile = () => window.innerWidth <= 768;

  // Toggle Sidebar
  const toggleSidebar = () => {
    if (isMobile()) {
      // En móvil, behave like drawer
      setIsMenuOpen(!isMenuOpen);
    } else {
      // En escritorio, colapsar/expandir
      setIsSidebarOpen(!isSidebarOpen);
      // Quita popouts para que no queden abiertos
      setPopoutSubMenu('');
    }
  };

  // Submenú
  const handleSubMenuClick = (menu, e) => {
    // Si sidebar abierto o móvil, submenú se expande normal
    if (isSidebarOpen || isMobile()) {
      setActiveSubMenu(activeSubMenu === menu ? '' : menu);
    } else {
      // Modo colapsado en escritorio → popout
      const rect = e.currentTarget.getBoundingClientRect();
      // Toggle submenú en popout
      setPopoutSubMenu(popoutSubMenu === menu ? '' : menu);
      setActiveSubMenu(activeSubMenu === menu ? '' : menu);
      // Calculamos la posición para que aparezca junto al ícono
      setPopoutPosition({
        x: rect.right + 5,      // un pequeño margen
        y: rect.top            // a la altura del elemento
      });
    }
  };

  // Navegación
  const handleNavigate = (path) => {
    navigate(path);
    if (isMobile()) {
      setIsMenuOpen(false);
    }
  };

  // Logout
  const handleLogoutClick = () => {
    logout();
    if (wsContext && wsContext.closeWebSocket) {
      wsContext.closeWebSocket();
    }
  };

  // Detección de ruta activa
  const isActiveRoute = (path) => location.pathname === path;

  // Config Lottie
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // Simulación latidos cada 30s
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationKey(prevKey => prevKey + 1);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // Render foto de perfil
  const renderProfilePicture = () => {
    const imageUrl = profileImageUrl || sidebarState.profileImageUrl;
    if (imageUrl) {
      return (
          <LazyLoadImage
              alt="Foto de Perfil"
              src={imageUrl}
              effect="blur"
              placeholderSrc={defaultProfilePicture}
              width="130px"
              height="130px"
              style={{ borderRadius: '50%', marginTop: '15px', objectFit: 'cover' }}
              onLoad={() => setImageLoaded(true)}
          />
      );
    } else {
      return (
          <Lottie
              options={{ loop: false, autoplay: true, animationData }}
              height={200}
              width={200}
          />
      );
    }
  };

  // Fetch foto de perfil
  useEffect(() => {
    const fetchProfileImage = async () => {
      if (!userData?.id_usuario) {
        return;
      }
      try {
        const response = await axios.get(`${API_BASE_URL}/cuenta/fotoPerfil/${userData.id_usuario}`);
        if (response.data.success && response.data.fotoPerfilUrl) {
          setProfileImageUrl(response.data.fotoPerfilUrl);
          const updatedUserData = { ...userData, fotoPerfilUrl: response.data.fotoPerfilUrl };
          localStorage.setItem('userData', JSON.stringify(updatedUserData));
        }
      } catch (error) {
        console.error('// LOG ERROR: [LayoutSide] fetchProfileImage =>', error);
      }
    };
    fetchProfileImage();
  }, [userData?.id_usuario]);

  /**
   * Maneja el hover de cada ítem para mostrar el tooltip.
   * Calcula su posición en la pantalla.
   */
  const handleMouseEnter = (itemName, e) => {
    if (!isSidebarOpen && !isMobile()) {
      setHoveredItem(itemName);
      const rect = e.currentTarget.getBoundingClientRect();
      setTooltipPosition({
        x: rect.right + 10,
        y: rect.top + rect.height / 2
      });
    }
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
      <LayoutContext.Provider value={{ activeSubMenu, setActiveSubMenu, handleNavigate }}>
        {/* Contenedor principal con position relativo */}
        <div style={{ display: 'flex', position: 'relative' }}>

          {/* BOTÓN TOGGLE */}
          <SidebarButton
              onClick={toggleSidebar}
              open={isSidebarOpen}
              style={{
                zIndex: 2000,
                color: '#fff'
              }}
          >
            {isMobile()
                ? (isMenuOpen ? <FaTimes /> : <FaBars />)
                : (isSidebarOpen ? <FaTimes /> : <FaBars />)
            }
          </SidebarButton>

          {/* SIDEBAR */}
          <Sidebar
              open={isSidebarOpen}
              menuOpen={isMenuOpen}
              collapsed={!isSidebarOpen}
              style={{
                transform: isMobile()
                    ? (isMenuOpen ? 'translateX(0)' : 'translateX(-100%)')
                    : 'translateX(0)',
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: isMobile() ? '250px' : (isSidebarOpen ? '250px' : '80px'),
                transition: 'width 0.3s ease-in-out, transform 0.3s ease-in-out',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
          >
            <div style={{paddingTop: !isSidebarOpen ? '50px' : '0'}}>
              {(!isMobile() && !isSidebarOpen) ? null : (
                  <UserInfo>
                    {renderProfilePicture()}
                    <div style={{paddingTop: '10px', fontWeight: 'bold'}}>
                      {userData?.name} {userData?.lastName}
                    </div>
                    <div>
                      {userData?.roles?.map(role => role.name).join(', ')}
                    </div>
                    <Sede>
                      Sede Actual: {nombreSedeActual}
                    </Sede>
                  </UserInfo>
              )}

              {/* MENÚ */}
              <Menu>
                {/* CHAT */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Chat', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={isActiveRoute('/dashboard/chat')}
                    onClick={() => handleNavigate('/dashboard/chat')}
                >
                  <FaComments/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Chat</span>}
                </MenuItem>

                {/* DASHBOARD */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Dashboard', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={isActiveRoute('/dashboard/dashboard-indicadores')}
                    onClick={() => handleNavigate('/dashboard/dashboard-indicadores')}
                >
                  <FaChartLine/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Dashboard</span>}
                </MenuItem>

                {/* ADMINISTRADOR */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Administrador', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={activeSubMenu === 'Administrador'}
                    onClick={(e) => handleSubMenuClick('Administrador', e)}
                >
                  <FaUsersCog/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Administrador</span>}
                  {/* Flecha (solo si abierto o móvil) */}
                  {!isMobile() && !isSidebarOpen ? null : (
                      <ChevronIcon
                          className="fa fa-chevron-up"
                          rotate={activeSubMenu === 'Administrador'}
                      />
                  )}
                </MenuItem>
                {(!isMobile() && !isSidebarOpen) ? null : (
                    <SubMenu active={activeSubMenu === 'Administrador'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/agregar-administrador')}>
                        Añadir
                      </SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-administrador')}>
                        Eliminar
                      </SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/actualizar-administrador')}>
                        Actualizar
                      </SubMenuItem>
                    </SubMenu>
                )}

                {/* MÉDICO */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Médico', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={activeSubMenu === 'Fisioterapeuta'}
                    onClick={(e) => handleSubMenuClick('Fisioterapeuta', e)}
                >
                  <FaUserMd/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Médico</span>}
                  {!isMobile() && !isSidebarOpen ? null : (
                      <ChevronIcon
                          className="fa fa-chevron-up"
                          rotate={activeSubMenu === 'Fisioterapeuta'}
                      />
                  )}
                </MenuItem>
                {(!isMobile() && !isSidebarOpen) ? null : (
                    <SubMenu active={activeSubMenu === 'Fisioterapeuta'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-medico')}>Añadir</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-medico')}>Eliminar</SubMenuItem>
                      <SubMenuItem
                          onClick={() => handleNavigate('/dashboard/actualizar-medico')}>Actualizar</SubMenuItem>
                      <SubMenuItem
                          onClick={() => handleNavigate('/dashboard/reingreso-medico')}>ReIngresar</SubMenuItem>
                    </SubMenu>
                )}

                {/* PACIENTES */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Pacientes', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={activeSubMenu === 'Pacientes'}
                    onClick={(e) => handleSubMenuClick('Pacientes', e)}
                >
                  <FaUserInjured/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Pacientes</span>}
                  {!isMobile() && !isSidebarOpen ? null : (
                      <ChevronIcon
                          className="fa fa-chevron-up"
                          rotate={activeSubMenu === 'Pacientes'}
                      />
                  )}
                </MenuItem>
                {(!isMobile() && !isSidebarOpen) ? null : (
                    <SubMenu active={activeSubMenu === 'Pacientes'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-paciente')}>Ingresar</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/alta-paciente')}>Dar de alta</SubMenuItem>
                      <SubMenuItem
                          onClick={() => handleNavigate('/dashboard/reingreso-paciente')}>Reingreso</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/calendario-citas')}>Calendario de
                        citas</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-evolucion')}>Ficha
                        Evolución</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-clinica')}>Ficha
                        clínica</SubMenuItem>
                    </SubMenu>
                )}

                {/* GESTIÓN DEL NEGOCIO */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Gestión del negocio', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={activeSubMenu === 'GestionNegocio'}
                    onClick={(e) => handleSubMenuClick('GestionNegocio', e)}
                >
                  <FaBusinessTime/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Gestión del negocio</span>}
                  {!isMobile() && !isSidebarOpen ? null : (
                      <ChevronIcon
                          className="fa fa-chevron-up"
                          rotate={activeSubMenu === 'GestionNegocio'}
                      />
                  )}
                </MenuItem>
                {(!isMobile() && !isSidebarOpen) ? null : (
                    <SubMenu active={activeSubMenu === 'GestionNegocio'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/gestion-servicios')}>Gestión de
                        servicios</SubMenuItem>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/reporte-servicios')}>Reporte de
                        servicios</SubMenuItem>
                    </SubMenu>
                )}

                {/* DISEÑADOR DINÁMICO */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Diseñador dinámico', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={activeSubMenu === 'DisenadorDinamico'}
                    onClick={(e) => handleSubMenuClick('DisenadorDinamico', e)}
                >
                  <FaTools/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Diseñador dinámico</span>}
                  {!isMobile() && !isSidebarOpen ? null : (
                      <ChevronIcon
                          className="fa fa-chevron-up"
                          rotate={activeSubMenu === 'DisenadorDinamico'}
                      />
                  )}
                </MenuItem>
                {(!isMobile() && !isSidebarOpen) ? null : (
                    <SubMenu active={activeSubMenu === 'DisenadorDinamico'}>
                      <SubMenuItem onClick={() => handleNavigate('/dashboard/form-builder')}>Fichas
                        Clínicas</SubMenuItem>
                    </SubMenu>
                )}

                {/* CONFIGURACIÓN */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Configuración', e)}
                    onMouseLeave={handleMouseLeave}
                    bold
                    active={isActiveRoute('/dashboard/configuracion')}
                    onClick={() => handleNavigate('/dashboard/configuracion')}
                >
                  <FaCog/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Configuración</span>}
                </MenuItem>

                {/* LOGOUT */}
                <MenuItem
                    sidebarOpen={isSidebarOpen}
                    onMouseEnter={(e) => handleMouseEnter('Cerrar sesión', e)}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleLogoutClick}
                >
                  <FaTimes/>
                  {(!isMobile() && !isSidebarOpen) ? null : <span>Cerrar sesión</span>}
                </MenuItem>
              </Menu>
            </div>

            {/* LOGO INFERIOR */}
            <SidebarLogoContainer>
              {logoUrl ? (
                  <SidebarLogo
                      src={logoUrl}
                      alt="Logo de la Empresa"
                      collapsed={!isSidebarOpen}
                  />
              ) : (
                  <SidebarLogo
                      src={defaultProfilePicture}
                      alt="Logo Predeterminado"
                      collapsed={!isSidebarOpen}
                  />
              )}
            </SidebarLogoContainer>
          </Sidebar>

          {/* POP-OUT SUBMENUS (solo si colapsado y en escritorio), se posicionan con position: fixed */}
          {(!isMobile() && !isSidebarOpen && popoutSubMenu === 'Administrador') && (
              <PopoutContainer style={{
                position: 'fixed',
                left: popoutPosition.x,
                top: popoutPosition.y
              }}>
                <SubMenu active={true} style={{ padding: '10px' }}>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/agregar-administrador')}>
                    Añadir
                  </SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-administrador')}>
                    Eliminar
                  </SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/actualizar-administrador')}>
                    Actualizar
                  </SubMenuItem>
                </SubMenu>
              </PopoutContainer>
          )}

          {(!isMobile() && !isSidebarOpen && popoutSubMenu === 'Fisioterapeuta') && (
              <PopoutContainer style={{
                position: 'fixed',
                left: popoutPosition.x,
                top: popoutPosition.y
              }}>
                <SubMenu active={true} style={{ padding: '10px' }}>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-medico')}>Añadir</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/eliminar-medico')}>Eliminar</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/actualizar-medico')}>Actualizar</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/reingreso-medico')}>ReIngresar</SubMenuItem>
                </SubMenu>
              </PopoutContainer>
          )}

          {(!isMobile() && !isSidebarOpen && popoutSubMenu === 'Pacientes') && (
              <PopoutContainer style={{
                position: 'fixed',
                left: popoutPosition.x,
                top: popoutPosition.y
              }}>
                <SubMenu active={true} style={{ padding: '10px' }}>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/ingresar-paciente')}>Ingresar</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/alta-paciente')}>Dar de alta</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/reingreso-paciente')}>Reingreso</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/calendario-citas')}>Calendario de citas</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-evolucion')}>Ficha Evolución</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/ficha-clinica')}>Ficha clínica</SubMenuItem>
                </SubMenu>
              </PopoutContainer>
          )}

          {(!isMobile() && !isSidebarOpen && popoutSubMenu === 'GestionNegocio') && (
              <PopoutContainer style={{
                position: 'fixed',
                left: popoutPosition.x,
                top: popoutPosition.y
              }}>
                <SubMenu active={true} style={{ padding: '10px' }}>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/gestion-servicios')}>Gestión de servicios</SubMenuItem>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/reporte-servicios')}>Reporte de servicios</SubMenuItem>
                </SubMenu>
              </PopoutContainer>
          )}

          {(!isMobile() && !isSidebarOpen && popoutSubMenu === 'DisenadorDinamico') && (
              <PopoutContainer style={{
                position: 'fixed',
                left: popoutPosition.x,
                top: popoutPosition.y
              }}>
                <SubMenu active={true} style={{ padding: '10px' }}>
                  <SubMenuItem onClick={() => handleNavigate('/dashboard/form-builder')}>Fichas Clínicas</SubMenuItem>
                </SubMenu>
              </PopoutContainer>
          )}

          {/* TOOLTIP GLOBAL (fuera del sidebar), con posición fija */}
          {(!isSidebarOpen && !isMobile() && hoveredItem) && (
              <Tooltip style={{
                position: 'fixed',
                top: tooltipPosition.y,
                left: tooltipPosition.x,
                transform: 'translateY(-50%)',
                zIndex: 9999
              }}>
                {hoveredItem}
              </Tooltip>
          )}

          <HeartIconAnimation animationData={heartAnimationData} />
          <NotificationsSlider />

          {/* CONTENIDO PRINCIPAL */}
          <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: isMobile() ? 0 : (isSidebarOpen ? '250px' : '80px'),
                width: '100%',
                transition: 'margin-left 0.3s',
                boxSizing: 'border-box',

              }}
          >
            <NotificationBellContainer>
              <NotificationBell />
            </NotificationBellContainer>
            <Outlet />
            <div>{children}</div>
          </div>
        </div>
      </LayoutContext.Provider>
  );
};

export default LayoutSide;
